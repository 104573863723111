/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';
import { Skeleton } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
  Legend,
} from 'recharts';

import { useTheme } from 'styled-components';
import { FormatTypes } from '../../services/helper';
import useIsMobile from '../../services/useIsMobile';
import MoneyPrefix from '../MoneyPrefix';
import {
  CustomLabelFunction,
  useFormatFunction,
  renderLegendFunction,
} from '../componentsFunctions';

interface IGraficoWaterfall {
  data: { name: string; value: number[] }[] | null;
  height?: number;
  onClickHandle?: (value: any) => void | null;
  valueType?: FormatTypes;
  decimals?: number;
  moneyPrefix?: string | null;
  colors: string | string[];
  hideXAxisLabels?: boolean;
  isPercentToggle?: boolean;
  isWaterfallHorizontal?: boolean;
  dispatchPDF?: boolean; // considerar a posição do texto como se fosse tela grande
  handleOnAnimationEnd?: () => void;
}

export const GraficoWaterfall = ({
  data,
  height,
  onClickHandle,
  colors,
  isPercentToggle,
  isWaterfallHorizontal,
  dispatchPDF = false,
  handleOnAnimationEnd,
  moneyPrefix,
}: IGraficoWaterfall) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [isAnimation, setIsAnimation] = useState(true);

  const useFormat = (value: any) => useFormatFunction(value, isPercentToggle, moneyPrefix);
  const renderLegend = (value: any) => renderLegendFunction(value, isPercentToggle, moneyPrefix);

  const handleClick = useCallback((entry: any) => {
    if (onClickHandle) onClickHandle(entry);
  }, []);

  const listaWaterfall = data?.map((obj) => {
    return obj.value[1];
  });

  const CustomLabel = ({
    moneyPrefix,
    x,
    y,
    value,
    width,
    height,
    listaValores,
  }: {
    moneyPrefix?: string | null;
    x?: number;
    y?: number;
    value?: any;
    width?: number;
    height?: number;
    listaValores?: number[];
  }) => {
    return CustomLabelFunction(
      moneyPrefix,
      x,
      y,
      value,
      width,
      height,
      isPercentToggle,
      false,
      dispatchPDF,
      isMobile,
      listaValores,
      isWaterfallHorizontal,
    );
  };

  if (isMobile) {
    isWaterfallHorizontal = true;
  }

  const handleAnimationEnd = () => {
    () => setIsAnimation(false);
    if (handleOnAnimationEnd) handleOnAnimationEnd();
  };

  const axisValues = data?.map((item) => item.value[1]);
  const maxAxisLabel = axisValues ? Math.max(...axisValues) : 0;
  const minAxisLabel = axisValues ? Math.min(...axisValues) : 0;

  return data && data.length > 0 ? (
    <div style={{ marginTop: 50, marginLeft: -10 }}>
      <ResponsiveContainer width={'100%'} height={isMobile ? 570 : 350}>
        <BarChart
          layout={!isWaterfallHorizontal ? 'horizontal' : 'vertical'}
          data={data}
          margin={{
            top: 15,
            right: 40,
            left: 40,
            bottom: 5,
          }}
          onClick={(data) => {
            if (
              data &&
              data.activePayload &&
              data.activePayload.length > 0 &&
              data.activePayload[0].payload.name !== 'Total'
            ) {
              handleClick(data.activePayload[0]);
            }
          }}>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            type={!isWaterfallHorizontal ? 'number' : 'category'}
            dataKey={!isWaterfallHorizontal ? '' : 'name'}
            tickFormatter={!isWaterfallHorizontal ? useFormat : undefined}
            domain={[minAxisLabel * 1.1, maxAxisLabel * 1.1]}
          />
          <XAxis
            type={!isWaterfallHorizontal ? 'category' : 'number'}
            dataKey={!isWaterfallHorizontal ? 'name' : ''}
            tickFormatter={!isWaterfallHorizontal ? undefined : useFormat}
            domain={
              isMobile
                ? maxAxisLabel <= 0
                  ? [minAxisLabel * 1.05, Math.abs(minAxisLabel / 7)]
                  : [minAxisLabel * 1.05, maxAxisLabel * 1.15]
                : [minAxisLabel * 1.2, maxAxisLabel * 1.25]
            }
            scale={isWaterfallHorizontal ? 'linear' : 'auto'}
          />
          {isMobile ? (
            <Legend
              payload={data.map((item, index) => ({
                id: item.name,
                type: 'square',
                value: item.value,
                color: theme.chartColors[index % theme.chartColors.length],
              }))}
              content={renderLegend}
              verticalAlign="bottom"
              style={{ height: '300px !important' }}
            />
          ) : null}
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            formatter={(value) => [useFormat(value), 'Valor']}
          />
          <Bar dataKey="value" onAnimationEnd={handleAnimationEnd} isAnimationActive={isAnimation}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                cursor="pointer"
                fill={typeof colors !== 'string' ? colors[index % colors.length] : colors}
              />
            ))}
            <LabelList
              dataKey="value"
              position="top"
              content={<CustomLabel listaValores={listaWaterfall} moneyPrefix={moneyPrefix} />}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <Skeleton variant="rectangular" height={height} width="100%" />
  );
};
