import '../style.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import 'svg2pdf.js'
import '../../../../assets/fonts/OpenSans-Regular-normal'
import '../../../../assets/fonts/OpenSans-Bold-bold'
import { DefaultTheme } from 'styled-components';

/**
 * Desenha uma página com o dislaimer no PDF
 * @param doc PDF
 * @param theme Tema da aplicação
 * @param disclaimer Texto do disclaimer
 * @param fontSize Tamanho da fonte
 */
export function desenharDisclaimerPDF(doc: jsPDF, theme: DefaultTheme, disclaimer: string, fontSize = 11, isUltimaPagina = false) {
    if(isUltimaPagina){
        doc.addPage();
    }
    doc.setTextColor(theme.titleFontColor);
    doc.setFontSize(12);
    doc.text('Disclaimer', 30, 30);
    doc.setFontSize(fontSize);
    doc.text(disclaimer, 30, 45, { maxWidth: 150, align: "justify", lineHeightFactor:1.5 });
    if(!isUltimaPagina){
        doc.addPage();
    }
}