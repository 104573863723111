import { fetchEstats } from './../api/estatsAPI';
import { fetchFluxoCaixa, fetchIFsCaixa } from './../api/fluxoCaixaAPI';
import { changePassword } from './../api/preferenceAPI';
import { fetchResumoCart, fetchResumoCartPerf } from './../api/resumoCartAPI';
import { fetchPerfHist, fetchPerfHistPerf } from './../api/perfHistAPI';
import { fetchModalPosCon, fetchPosCon } from './../api/posConAPI';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCarteiraList, fetchPreference, fetchPreferenceWhitelabel } from '../api/preferenceAPI';
import { RootState } from '../store';
import { checkPeriodoPersonalizado, compareDatesDDMMYYYY, changeTimezone } from '../helper';
import { fetchFluxoAtivos } from '../api/fluxoAtivosAPI';
import { fetchFulfilledStateBuilder, fetchRejectedStateBuilder } from './helperFetchReducer';
import {IPreferenceStyle} from '../PreferenceStyleInterface/IPreferenceStyle';

export interface ICarteira {
  nome_portfolio: string;
  alias_nome_portfolio: string;
  data_mais_antiga_considerada: string;
  data_mais_recente_portfolio_AAAAmmDD: string;
  pl_data_mais_recente: number;
  data_mais_antiga_portfolio_AAAAmmDD: string;
  calendario: string;
  datas_nao_uteis: string[];
  omitir_retornos: string;
  data_ini_acomp_AAAAmmDD: string;
  moeda: string;
  unidade_monetaria: string;
  disclaimer: string | null;
  CV_bench_opcao: {
    label: string;
    value: string;
  }[];
  CV_bench_carteira1: string[];
  CV_bench_mes_a_mes: string[];
  CV_bench_liberado: string[];
}

export interface IToken {
  value: string | null;
  expiration: number | null;
}

export interface IParams {
  carteira: string | null;
  periodo: string;
  [key: string]: string | number | null;
}

export interface IPreferenceState {
  loading: boolean;
  loadingCarteiraList: boolean;
  loadedCarteiraList: boolean;
  loadingTrocarSenha: boolean;
  trocarSenhaStatus: 'ok' | 'error' | 'none';
  loginError: boolean;
  unauthorized: boolean;
  recalcular: boolean;
  token: IToken;
  usuario: string;
  id: number | null;
  carteiraList: ICarteira[];
  flagTrocaSenha: string | never[];
  dateStart: string;
  dateEnd: string;
  params: IParams;
  loggedIn: boolean;
  preferenceStyle: IPreferenceStyle;
  modalDisclaimerActive: boolean;
}

const initialState: IPreferenceState = {
  loading: false,
  loadingCarteiraList: false,
  loadedCarteiraList: false,
  loadingTrocarSenha: false,
  trocarSenhaStatus: 'none',
  loginError: false,
  unauthorized: false,
  recalcular: false,
  token: {
    value: null,
    expiration: null,
  },
  usuario: '',
  id: null,
  carteiraList: [],
  flagTrocaSenha: '',
  dateStart: '',
  dateEnd: '',
  params: {
    carteira: null,
    periodo: '',
  },
  loggedIn: false,
  preferenceStyle: {
    estilo: null,
    login: null,
    imagem: null,
    imagemAPI :null,
    customizacao: null,
    liberacao: null,
    layoutPDF: null,
  },
  modalDisclaimerActive: true,
};

export const preferenceSlice = createSlice({
  name: 'preference',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
    setToken: (state, action: PayloadAction<{ token: string; expiration: number | null }>) => {
      state.token.value = action.payload.token ?? null;
      state.token.expiration = action.payload.expiration ?? null;
    },
    setStatusSenha: (state, action: PayloadAction<'ok' | 'error' | 'none'>) => {
      state.trocarSenhaStatus = action.payload;
    },
    resetParams: (state) => {
      // mantém o atributo preferenceStyle.login, porque não deve resetar o estilo da página de login quando o usuário deslogar
      const newState = {
        ...initialState,
        preferenceStyle: {
          ...initialState.preferenceStyle,
          login: state.preferenceStyle.login,
        },
      };
      return newState;
    },
    setCarteiraID: (state, action: PayloadAction<string | null>) => {
      state.params.carteira = action.payload;
    },
    toggleRecalcular: (state) => {
      state.recalcular = !state.recalcular;
    },
    setRecalcular: (state, action) => {
      state.recalcular = action.payload;
    },
    resetStateHelper: (state) => {
      state.loadedCarteiraList = false;   // recarregar a CarteiraList ao recalcular
      return state;
    },
    setParams: (state, action: PayloadAction<IParams>) => {
      for (const prop in state.params) {
        if (
          Object.hasOwnProperty.call(state.params, prop) &&
          Object.hasOwnProperty.call(action.payload, prop) &&
          action.payload[prop]
        ) {
          state.params[prop] = action.payload[prop];
        }
      }

      const matches = checkPeriodoPersonalizado(state.params.periodo ?? '');
      if (matches) {
        state.dateStart = matches.start;
        state.dateEnd = matches.end;
      }
    },
    setDataPersonalizada: (state, action) => {
      state.dateStart = action.payload.dataIni;
      state.dateEnd = action.payload.dataFim;
      state.params.periodo = `${action.payload.dataIni}%20${action.payload.dataFim}`;
    },
    setData: (state, action) => {
      state.params.periodo = action.payload;
    },
    setDataFim: (state, action) => {
      const matches = checkPeriodoPersonalizado(state.params.periodo ?? '');
      if (matches) {
        const startDate = (/^\d+$/.test(matches.start) && compareDatesDDMMYYYY(matches.start, action.payload) >= 0) ? 'maximo' : matches.start;
        state.params.periodo = `${startDate}%20${action.payload}`;
      } else {
        state.params.periodo = `${state.params.periodo}%20${action.payload}`;
      }
    },
    setPreferenceStyle: (state, action) => {
      state.preferenceStyle= action.payload;
    },
    setModalDisclaimerActive: (state, action: PayloadAction<boolean>) => {
      state.modalDisclaimerActive = action.payload;
      window.sessionStorage.setItem("disclaimer", JSON.stringify(action.payload));  // guarda no session do navegador, para manter estado ao recarregar pgina
    },
  },

  extraReducers: (builder) => {
    // change password cases
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loadingTrocarSenha = false;
      state.trocarSenhaStatus = 'ok';
    });
    builder.addCase(changePassword.pending, (state) => {
      state.loadingTrocarSenha = true;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.loadingTrocarSenha = false;
      const payload = action.payload as { errors: { msg: string; codigo: string }[] };
      if (payload.errors[0] && payload.errors[0].codigo === '1b6321') {
        state.trocarSenhaStatus = 'error';
      }
    });

    // login cases
    builder.addCase(fetchPreference.pending, (state) => {
      state.loading = true;
      state.loginError = false;
    });
    builder.addCase(fetchPreference.fulfilled, (state, action) => {
      state.loading = false;
      state.flagTrocaSenha = action.payload.usuario_final?.recadastrar_senha_proximo_acesso ?? [];
      if(action.payload.usuario_final?.recadastrar_senha_proximo_acesso) localStorage.setItem('flagTrocaSenha', action.payload.usuario_final?.recadastrar_senha_proximo_acesso)
      if (action.payload.access_token) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in
          ? changeTimezone(new Date(action.payload.expires_in), "America/Sao_paulo").getTime()
          : null;
        window.localStorage.setItem(
          `token-${(window as any).env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
      if(action.payload.preference_whitelabel){
        state.preferenceStyle = {...state.preferenceStyle, ...action.payload.preference_whitelabel};
      }
    });
    builder.addCase(fetchPreference.rejected, (state) => {
      state.loading = false;
      state.loginError = true;
    });

    // list portfolio cases
    builder.addCase(fetchCarteiraList.pending, (state) => {
      state.loadingCarteiraList = true;
    });
    builder.addCase(fetchCarteiraList.fulfilled, (state, action) => {
      state.loadedCarteiraList = true;
      state.loadingCarteiraList = false;
      state.carteiraList = action.payload.ListaCarteiras ?? [];

      if (action.payload.access_token && action.payload.expires_in) {
        state.token.value = action.payload.access_token;
        state.token.expiration = action.payload.expires_in * 1000;

        window.localStorage.setItem(
          `token-${(window as any).env.REACT_APP_NAME ?? 'comdinheiro'}`,
          JSON.stringify({ ...state.token }),
        );
      }
    });

    builder.addCase(fetchPreferenceWhitelabel.fulfilled, (state, action) => {
      if(action.payload.response){
        state.preferenceStyle = {...state.preferenceStyle, ...action.payload.response};
      }
    });

    // expired token cases for each API call
    fetchRejectedStateBuilder(builder, fetchResumoCart);
    fetchRejectedStateBuilder(builder, fetchResumoCartPerf);
    fetchRejectedStateBuilder(builder, fetchCarteiraList);
    fetchRejectedStateBuilder(builder, fetchPreferenceWhitelabel);
    fetchRejectedStateBuilder(builder, fetchPerfHist);
    fetchRejectedStateBuilder(builder, fetchPerfHistPerf);
    fetchRejectedStateBuilder(builder, fetchPosCon);
    fetchRejectedStateBuilder(builder, fetchModalPosCon);
    fetchRejectedStateBuilder(builder, fetchFluxoAtivos);
    fetchRejectedStateBuilder(builder, fetchFluxoCaixa);
    fetchRejectedStateBuilder(builder, fetchIFsCaixa);
    fetchRejectedStateBuilder(builder, fetchEstats);

    // set new token and date cases for each API call
    fetchFulfilledStateBuilder(builder, fetchResumoCart, 'ResumoCart');
    fetchFulfilledStateBuilder(builder, fetchResumoCartPerf, '');
    fetchFulfilledStateBuilder(builder, fetchPerfHist, 'PerfHist');
    fetchFulfilledStateBuilder(builder, fetchPerfHistPerf, '');
    fetchFulfilledStateBuilder(builder, fetchPosCon, 'PosCon');
    fetchFulfilledStateBuilder(builder, fetchModalPosCon, '');
    fetchFulfilledStateBuilder(builder, fetchFluxoAtivos, 'FluxoAtivos');
    fetchFulfilledStateBuilder(builder, fetchFluxoCaixa, 'FluxoCaixa');
    fetchFulfilledStateBuilder(builder, fetchIFsCaixa, 'IFsCaixa');
    fetchFulfilledStateBuilder(builder, fetchEstats, 'EstatAvanc');
  },
});

export const {
  setLoggedIn,
  setToken,
  setStatusSenha,
  resetParams,
  setDataPersonalizada,
  setData,
  setDataFim,
  setParams,
  setCarteiraID,
  setModalDisclaimerActive,
  toggleRecalcular,
  setRecalcular,
  resetStateHelper,
  setPreferenceStyle,
} = preferenceSlice.actions;

export const selectToken = (state: RootState) => state.preference.token;

export default preferenceSlice.reducer;
