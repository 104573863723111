import React from 'react';
import { ReactComponent as PDDFSymbol } from '../../assets/PDFSymbol.svg';
import { useTheme } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { LineSmall } from '../Line';
import { Container, BotaoPDFContainer, BotaoPDFText } from './style';
import { useAppSelector } from '../../services/reduxHooks';
import { Skeleton } from '@mui/material';
import { typeAbas,ABAS_DEFAULT } from '../../services/PreferenceStyleInterface/IPreferenceLiberacao';

export default function BotaoGerarPDF() {
  const { search, pathname } = useLocation();
  const theme = useTheme();
  const [
    isLoadedResumoCart,
    isLoadedResumoCartPerf, // apenas para azimut que usa percent na tabela sempre
    isLoadedPerfHist,
    isLoadedPosCon,
    isLoadedCartExp,
    isLoadedFluxoAtivos,
    isLoadedEstats,
    preferenceCustomizacao,
    preferenceLiberacao
  ] = useAppSelector((state) => [
    state.resumoCart.isLoaded,
    state.resumoCart.isLoadedPercent, // apenas para azimut que usa percent na tabela sempre
    state.perfHist.isLoaded,
    state.posCon.isLoaded,
    state.cartExp.isLoaded,
    state.fluxoAtivos.isLoaded,
    state.estats.isLoaded,
    state.preference.preferenceStyle.customizacao,
    state.preference.preferenceStyle.liberacao,
  ]);

  // 20/02/2025: NÃO FUNCIONA COM O PDF ATUAL, APENAS COM O PDF QUE ACEITA LAYOUTS DA ISSUE CMDDAPP-576
  // const abas = preferenceLiberacao?.abas ?? ABAS_DEFAULT;

  // const mapeamentoIsLoaded: Partial<Record<typeAbas, boolean>> = {
  //   ResCart: isLoadedResumoCart,
  //   PerfHist: isLoadedPerfHist,
  //   PosCon: isLoadedPosCon,
  //   CartExp: isLoadedCartExp,
  //   FluxoAtivo: isLoadedFluxoAtivos,
  //   EstatAvancada: isLoadedEstats,
  // }

  // let checkLoaded = true;

  // for (const page of abas) {
  //   if (page in mapeamentoIsLoaded) {
  //     checkLoaded &&= (mapeamentoIsLoaded[page] ?? true);
  //   }
  // }
  // if (preferenceCustomizacao?.ResCart_percent) checkLoaded &&= isLoadedResumoCartPerf;

  let checkLoaded = isLoadedResumoCart && isLoadedPerfHist && isLoadedPosCon && isLoadedFluxoAtivos && isLoadedEstats;
  if((preferenceCustomizacao?.ResCart_percent ?? false)){
    checkLoaded = checkLoaded && isLoadedResumoCartPerf;
  }
  const possuiCartExp = preferenceLiberacao?.abas.includes('CartExp');
  if(possuiCartExp){
    checkLoaded = checkLoaded && isLoadedCartExp;
  }

  return (
    <Container>
      <BotaoPDFContainer>
        {checkLoaded ? (
          <Link
            style={{
              textDecoration: 'none',
              color: 'inherit',
              alignItems: 'center',
              display: 'flex',
              gridGap: '5px',
            }}
            to={{ pathname: '/pdf', search }}
            state={pathname}>
            <PDDFSymbol width={22} height={22} fill={theme.iconsColor} />
            <BotaoPDFText>Gerar PDF</BotaoPDFText>
          </Link>
        ) : (
          <Skeleton width={60} style={{cursor: 'wait'}} />
        )}

        <LineSmall />
      </BotaoPDFContainer>
    </Container>
  );
}
