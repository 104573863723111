/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useCallback } from 'react';
import { Box, Grid, Skeleton } from '@mui/material';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareFull } from '@fortawesome/free-solid-svg-icons';
import { Item } from './style';
import { helperFormat, FormatTypes } from '../../services/helper';

/** Returns legend code (A, B, C, ...) based on the series index (0, 1, 2, ...) */
const getCode = function (index: number) {
  return String.fromCharCode(65 + (index % 26)).repeat(Math.floor(index / 26) + 1);
};

const gridItem = ({
  index,
  id,
  value,
  color,
  onClick,
}: {
  index: number;
  id: string | undefined;
  value: string | number;
  color: string;
  onClick?: () => void;
}) => {
  return (
    <Grid key={`item-${index}`} item xs={6}>
      <Item onClick={onClick} style={{ cursor: 'pointer' }}>
        {id ? (
          <>
            <Grid
              container
              style={{
                color: '#596579',
                fontWeight: 500,
                padding: '10px',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
                lineHeight: 1.2,
              }}>
              <Grid item xs={9} sm={10} style={{ paddingRight: '8px' }}>
                <FontAwesomeIcon
                  icon={faSquareFull as IconProp}
                  style={{ color, marginRight: 4 }}
                />{' '}
                <span>
                  {getCode(index)}. {id}
                </span>
              </Grid>
              <Grid item xs={3} sm={2}>
                <Box display="flex" justifyContent="flex-end">
                  <b>{value}</b>
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Item>
    </Grid>
  );
};

interface IGraficoPizza {
  series: { name: string; value: number; color?: string }[] | null;
  height?: number;
  onClickHandle?: (value: any) => void | null;
  FormatTypeParam?: FormatTypes;
  handleAnimationEnd?: any;
  dispatchPDF?: boolean;
  moneyPrefix?: string | null;
}

export function GraficoPizza({ series, height, onClickHandle, FormatTypeParam = FormatTypes.percentage, handleAnimationEnd, dispatchPDF, moneyPrefix }: IGraficoPizza) {
  const theme = useTheme();

  const handleClick = useCallback((entry: any) => {
    if (onClickHandle) onClickHandle(entry);
  }, []);

  let smallSlice = false;
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = (arg: any) => {
    const x = arg.cx + (arg.outerRadius + 10) * Math.cos(-arg.midAngle * RADIAN);
    const y = arg.cy + (arg.outerRadius + 10) * Math.sin(-arg.midAngle * RADIAN);

    if (arg.index == 0) smallSlice = false;

    // Se a slice anterior for considerada pequena, ela aparecerá normalmente; no entanto, as slices subsequentes não aparecerão.
    if (smallSlice) return;

    if (arg.percent < 0.02) smallSlice = true;

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > arg.cx ? 'start' : 'end'}
        dominantBaseline="central">
        {getCode(arg.index)}
      </text>
    );
  };

  const renderLegend = (props: any) => {
    const { payload }: { payload: Payload[] } = props;

    if (payload.length % 2 !== 0) {
      payload.push({ id: '', value: '' });
    }
    return (
      <Grid
        container
        style={{ marginTop: 16, fontSize: 12, height: 130 }}
        spacing={{ xs: 3, sm: 2.2, xl: 1 }}
        justifyContent="space-evenly">
        {payload.map((entry, index) => (
          <React.Fragment key={index}>
            {gridItem({
              index,
              id: entry.id,
              value: entry.value,
              color: entry.color ?? theme.chartColors[index % theme.chartColors.length],
              onClick: () => handleClick(entry.id),
            })}
          </React.Fragment>
        ))}
      </Grid>
    );
  };

  return series && series.length > 0 ? (
    <ResponsiveContainer width="100%" height={height}>
      <PieChart style={{ margin: 'auto' }}>
        <Pie
          data={series}
          dataKey={'value'}
          startAngle={90}
          endAngle={-270}
          outerRadius={dispatchPDF ? 180 : '85%'}
          innerRadius={dispatchPDF ? 110 : '52%'}
          fill={'green'}
          cx={'50%'}
          cy={'50%'}
          labelLine={false}
          label={renderCustomizedLabel}
          onClick={(data) => {
            if (data && data.name) {
              handleClick(data.name);
            }
          }}
          onAnimationEnd={handleAnimationEnd}>
          {series.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              style={{ outline: 'none' }}
              cursor="pointer"
              fill={entry.color ?? theme.chartColors[index % theme.chartColors.length]}
            />
          ))}
        </Pie>
        <Tooltip
          formatter={(value, name) => [
            helperFormat(value, FormatTypeParam, 2, moneyPrefix ?? 'R$'),
            name,
          ]}
        />
        {dispatchPDF ? null :
        <Legend
          payload={series.map((item, index) => ({
            id: item.name,
            type: 'square',
            value: helperFormat(item.value, FormatTypeParam, 2, moneyPrefix ?? 'R$'),
            color: item.color ?? theme.chartColors[index % theme.chartColors.length],
          }))}
          content={renderLegend}
          verticalAlign="bottom"
          style={{ height: '200px !important' }}
        />}
      </PieChart>
    </ResponsiveContainer>
  ) : (
    <Skeleton variant="circular" height={height} width="100%" />
  );
}
