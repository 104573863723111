import React, { useEffect, useRef, useState, Suspense } from 'react';

import ResumoCarteira from '../../ResumoCarteira';
import PerformanceHistorica from '../../PerformanceHistorica';
import PosicaoConsolidada from '../../PosicaoConsolidada';
import RetornosAtivos from '../../RetornoAtivo';
import CarteiraExplodida from '../../CarteiraExplodida';
import FluxoAtivos from '../../FluxoAtivos';
import EstatisticasAvancadas from '../../EstatisticasAvancadas';
import { IPageData } from '../index';
import { typeAbas } from '../../../../services/PreferenceStyleInterface/IPreferenceLiberacao';

interface Props {
    setPageData: React.Dispatch<React.SetStateAction<IPageData>>;
    abas: typeAbas [];
    moneyPrefix: string;
}

/**
 * Gera os componetes das páginas para obter os dados das tabelas e gráficos
 * @param setPageData Função para guardar o dado da página
 * @param abas Lista de páginas do PDF
 * @param moneyPrefix Unidade monetária da carteira
 * @returns JSX com todos os componentes das páginas
 */
export default function GerarPaginas({ setPageData, abas, moneyPrefix }: Props) {

    const setDataForPage = ((nome_pagina: string, data: any) => {
        setPageData((prevState: any) => ({
            ...prevState,
            [nome_pagina]: data,
        }));
    })

    const components = {
        'ResCart': <ResumoCarteira showCards={false} showPieChart={true} dispatchPDF={true} sendData={setDataForPage} moneyPrefixParam={moneyPrefix}/>,
        'PerfHist': <PerformanceHistorica dispatchPDF={true} sendData={setDataForPage} />,
        'PosCon': <PosicaoConsolidada dispatchPDF={true} sendData={setDataForPage} />,
        'RetornoAtivo': <RetornosAtivos dispatchPDF={true} sendData={setDataForPage} />,
        'CartExp': <CarteiraExplodida dispatchPDF={true} sendData={setDataForPage} />,
        'FluxoAtivo': <FluxoAtivos dispatchPDF={true} sendData={setDataForPage} />,
        'EstatAvancada':  <EstatisticasAvancadas dispatchPDF={true} sendData={setDataForPage} moneyPrefixParam={moneyPrefix}/>,
    }
    return (
        <>
            {abas.map((key: any) => (
                <div key={key}>{components[key as keyof typeof components]}</div>
            ))}
        </>
    );
}