import '../style.css';
import 'jspdf-autotable';
import 'svg2pdf.js'
import '../../../../assets/fonts/OpenSans-Regular-normal'
import '../../../../assets/fonts/OpenSans-Bold-bold'
import { docJSPDF } from '../Components/docJSPDF';
import { ComponentPDF, IConfig } from '../Components/ComponentPDF';
import { layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';

import * as funcoesPDF from '../Biblioteca/FuncoesPDF'

export abstract class PagePDF {
    pageName = '';
    docObj: docJSPDF;
    layout: IConfig[];
    data: any;

    constructor(docObj: docJSPDF, pageName: string, data: any, layout: layoutType) {
        this.docObj = docObj;
        this.pageName = pageName;
        this.data = data;

        this.layout = funcoesPDF.processaLayout(layout, this.docObj.width, this.docObj.MARGIN_LEFT_RIGHT);
    }
}