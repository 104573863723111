import { ITableColumns } from '../../../../components/Table/TableHeader';
import { docJSPDF } from '../Components/docJSPDF';
import { GraficoPizzaPDF } from '../Components/Graficos/GraficoPizzaPDF';
import { layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';

import * as funcoesPDF from '../Biblioteca/FuncoesPDF'
import { ComponentPDF } from '../Components/ComponentPDF';
import { PagePDF } from './PagePDF';
import { IAviso } from '../../../../services/reducers/cartExpSlice';
import { IObjDatas } from '../../../../services/reducers/helperReducers';

export interface ICartExpData {
    rowsDadosAgrupados: any,
    columnsDadosAgrupados: ITableColumns,
    aviso_carteira_explodida: IAviso,
    pieChartClasseData: any,
    obj_datas: IObjDatas
}

const DEFAULT_LAYOUT = [
    'pizzaCartExp',
    'tabelaCartExp'
];

export class CartExpPDF extends PagePDF{
    htmlElements: any;

    constructor(docObj: docJSPDF, data: ICartExpData, htmlElements: any, layout?: layoutType) {
        super(docObj, 'CartExp', data, layout ?? DEFAULT_LAYOUT);
        this.htmlElements = htmlElements;
    }

    /**
     * Gera um array com todos os componentes da página, utilizando o layout
     *
     * @returns Promise<ComponentPDF[]> Array com todos os componentes da página
     */
    async createPage(): Promise<ComponentPDF[]> {
        const components: ComponentPDF[] = [];
        const tituloComEstilo = this.docObj.criarTituloPagina('Carteira Explodida');
        components.push(funcoesPDF.gerarTexto(tituloComEstilo, this.pageName, {level: 0}));

        for (const config of this.layout) {
            let component = null;
            switch (config.id) {
                case 'pizzaCartExp': {
                    const format_data = this.data.aviso_carteira_explodida?.data_fim_AAAAmmDD_cart_explodida.split("-").reverse().join("/");
                    let text = '';
                    // if(aviso_carteira_explodida?.explosao_xml_com_data_fim_alterada){  // 06/01/2025: bug na API, sempre retorna como false, entao nao da para utilizar
                    if(format_data !== this.data.obj_datas.data_fim){
                        text = 'Carteira Explodida em ' + format_data
                    }
                    const pizzaClasse = new GraficoPizzaPDF(this.pageName, config, this.htmlElements['pizzaCartExp'], text, this.data.pieChartClasseData, true, undefined, undefined, 'left');
                    component = await pizzaClasse.criarGrafico(this.docObj);
                    if(component?.config){
                        component.config.level = 0;
                    }
                    if(component?.config.size){ // considera o titulo da página no cálculo do tamanho
                        component.config.size += this.docObj.marginBottomTitle;
                    }
                    break;
                }
                case 'tabelaCartExp': {
                    component = this.createConfigTable(config, this.data.rowsDadosAgrupados, this.data.columnsDadosAgrupados);
                    break;
                }
            }
            if (component) {
                components.push(component);
            }
        }
        if (components.length === 1) return [];   // só possui o título, então não deve incluir
        return components;
    }

    /**
     * Cria os atributos da tabela para o jsPDF. Gera a tabela com cores
     * @param config Configurações do componente
     * @param row Linha
     * @param col Coluna
     * @returns ComponentPDF Componente da tabela para o PDF
     */
    private createConfigTable(config: any, row: any, col: any): ComponentPDF | null {
        // Função passada como parâmetro para adiciona as cores nas linhas da tabela
        const generateStyle = (cell: any) => {
            if (cell.bgColor) {
                const styles = {
                    textColor: cell.color,
                    fillColor: cell.bgColor,
                    bold: cell.bold
                }
                return styles;
            } else {
                return {};
            }
        }

        const tableOptions = {
            headStyles: { halign: 'center', textColor: this.docObj.theme.titleFontColor },
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            columnStyles: { 0: { halign: 'left' } },
            styles: {
                halign: 'right',
                minCellHeight: 4,
            },
            body: funcoesPDF.createTableBody(row, col, null, generateStyle),
            head: funcoesPDF.createTableHead(col),
        }
        return funcoesPDF.gerarTabela(config, this.pageName, tableOptions);
    }
}
