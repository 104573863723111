import { ITableColumns } from '../../../../components/Table/TableHeader';
import { docJSPDF } from '../Components/docJSPDF';
import { ComponentPDF, IConfig } from '../Components/ComponentPDF';
import { layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';

import * as funcoesPDF from '../Biblioteca/FuncoesPDF'
import { PagePDF } from './PagePDF';

export interface IEstatAvancadaData {
    rowsInfos1: any,
    columnsInfos1: ITableColumns,
    rowsInfos2: any,
    columnsInfos2: ITableColumns,
    rowsRetornos: any,
    rowsVol: any,
    rowsSharpe: any,
    columnsDefault: ITableColumns
}

const DEFAULT_LAYOUT = [
    [{id: 'info1', col: 6}, {id: 'info2', col: 6}],
    'retorno',
    'vol',
    'sharpe'
];

export class EstatAvancandaPDF extends PagePDF {
    constructor(docObj: docJSPDF, data: IEstatAvancadaData, layout?: layoutType) {
        super(docObj, 'EstatAvancada', data, layout ?? DEFAULT_LAYOUT);
    }

    /**
     * Gera um array com todos os componentes da página, utilizando o layout
     *
     * @returns ComponentPDF[] Array com todos os componentes da página
     */
    createPage(): ComponentPDF[] {
        const components: ComponentPDF []= [];
        const tituloComEstilo = this.docObj.criarTituloPagina('Estatísticas Avançadas');
        components.push(funcoesPDF.gerarTexto(tituloComEstilo, this.pageName, {level: 0}));
        for(const config of this.layout){
            let table = null;
            switch(config.id){
                case 'info1': {
                    const cellWidth = config.col === 12 ? 140 : 45;
                    table = this.createConfigTable(config, this.data.rowsInfos1, this.data.columnsInfos1, 'Informações Adicionais', cellWidth);
                    break;
                }
                case 'info2': table = this.createConfigTable(config, this.data.rowsInfos2, this.data.columnsInfos2, ''); break;
                case 'retorno': table = this.createConfigTable(config, this.data.rowsRetornos, this.data.columnsDefault, 'Retorno', 30); break;
                case 'vol': table = this.createConfigTable(config, this.data.rowsVol, this.data.columnsDefault, 'Volatilidade', 30); break;
                case 'sharpe': table = this.createConfigTable(config, this.data.rowsSharpe, this.data.columnsDefault, 'Índice Sharpe', 30); break;
            }
            if(table){
                components.push(table);
            }
        }
        if(components.length === 1) return [];   // só possui o título, então não deve incluir
        return components;
    }

    /**
     * Cria os atributos da tabela para o jsPDF
     * @param config Configurações do componente
     * @param row Linha
     * @param col Coluna
     * @param title Titulo
     * @param cellWidth (Default auto) Tamanho da celula
     * @returns ComponentPDF Componente da tabela para o PDF
     */
    private createConfigTable(config: any, row: any, col: ITableColumns, title: string, cellWidth?: number): ComponentPDF | null {
        const titleConfig = this.docObj.criarTituloTabela(title);
        const tableOptions = {
            headStyles: { halign: 'right', textColor: this.docObj.theme.titleFontColor },
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            columnStyles: { 0: { halign: 'left', cellWidth: cellWidth || 'auto' } },
            styles: {
                halign: 'right',
                minCellHeight: 6,
            },
            body: funcoesPDF.createTableBody(row, col),
            head: funcoesPDF.createTableHead(col),
        }
        return funcoesPDF.gerarTabela(config, this.pageName, tableOptions, titleConfig);
    }
}