import { ITableColumns } from '../../../../components/Table/TableHeader';
import { docJSPDF } from '../Components/docJSPDF';
import { GraficoPizzaPDF } from '../Components/Graficos/GraficoPizzaPDF';
import { GraficoBarraPDF } from '../Components/Graficos/GraficoBarraPDF';
import { layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';

import * as funcoesPDF from '../Biblioteca/FuncoesPDF'
import * as helperPDF from '../Biblioteca/helperPDF'
import { ComponentPDF } from '../Components/ComponentPDF';
import { PagePDF } from './PagePDF';

import { FormatTypes } from '../../../../services/helper';
import { IPreferenceCustomizacao } from '../../../../services/PreferenceStyleInterface/IPreferenceCustomizacao';
import { IObjDatas } from '../../../../services/reducers/helperReducers';
import { PerfHistPDF } from './PerfHistPDF';

export interface IResCartData {
    cardsData: any,
    rowsRetorno: any,
    columnsRetorno: ITableColumns,
    rowsLiq: any,
    columnsLiq: ITableColumns,
    rowsClasse: any,
    columnsClasse: ITableColumns,
    rowsMovim: any,
    columnsMovim: ITableColumns,
    pieChartClassData: any,
    pieChartCustodianteData: any,
    obj_datas: IObjDatas,
}

const DEFAULT_LAYOUT = [
    'Cards',
    [{ id: 'Retorno', col: 8 }, { id: 'FluxoResgate', col: 4 }],
    [{ id: 'Posicao', col: 8, heightID: 'Retorno' }, { id: 'Movim', col: 4, heightID: 'FluxoResgate' }],
    ['graficoClasse', 'graficoCustodiante'],
];

export class ResCartPDF extends PagePDF{
    htmlElements: any;
    preferenceCustomizacao: IPreferenceCustomizacao | null;
    perfHistPDF?: PerfHistPDF | null;
    moneyPrefix?: string;

    constructor(docObj: docJSPDF, data: IResCartData, htmlElements: any, preferenceCustomizacao: IPreferenceCustomizacao | null, layout?: layoutType, perfHistPDF?: PerfHistPDF | null, moneyPrefix?: string) {
        super(docObj, 'ResCart', data, layout ?? DEFAULT_LAYOUT);
        this.htmlElements = htmlElements;
        this.preferenceCustomizacao = preferenceCustomizacao;
        this.perfHistPDF = perfHistPDF;
        this.moneyPrefix = moneyPrefix;
    }

    /**
     * Gera um array com todos os componentes da página, utilizando o layout
     *
     * @returns Promise<ComponentPDF[]> Array com todos os componentes da página
     */
    async createPage():  Promise<ComponentPDF[]> {
        const components: ComponentPDF[] = [];
        const tituloComEstilo = this.docObj.criarTituloPagina('Resumo Carteira', { marginBottom: 5 });
        components.push(funcoesPDF.gerarTexto(tituloComEstilo, this.pageName, {level: 0}));

        for (const config of this.layout) {
            let component = null;
            switch (config.id) {
                case 'Cards': {
                    const cardsElement: funcoesPDF.IHTML = {
                        element: this.htmlElements['cards'],
                        x: 10,
                        scale: 0.2,
                        windowWidth: 1300,
                        windowHeight: 100,
                        marginBottom: 25
                    }
                    component = funcoesPDF.gerarHTML(config, this.pageName, cardsElement);
                    break;
                }
                case 'Retorno': {
                    const didParseCell = (data: any) => { // adiciona bold nas duas primeiras linhas da tabela
                        if ((this.preferenceCustomizacao?.ResCart_boldRetornoNomial ?? false) && data.section === 'body' && (data.row.index === 0 || data.row.index === 1)) {
                            data.cell.styles.fontStyle = 'bold';
                        }
                    }
                    const title = `Retorno da Carteira x Benchmarks. Período de análise: de ${this.data.obj_datas.data_ini} a ${this.data.obj_datas.data_fim}`;
                    component = this.createConfigTable(config, this.data.rowsRetorno, this.data.columnsRetorno, title, 0, didParseCell);
                    break;
                }
                case 'FluxoResgate': component = this.createConfigTable(config, this.data.rowsLiq, this.data.columnsLiq, 'Fluxo de Resgate'); break;
                case 'Posicao': {
                    const body = helperPDF.gerarBodyComCor(this.data.rowsClasse, this.data.columnsClasse, this.docObj.theme, 3);
                    component = this.createConfigTable(config, null, ['', ...this.data.columnsClasse], 'Posição e Rendimento por Classe', 1, null, body);
                    break;
                }
                case 'Movim': component = this.createConfigTable(config, this.data.rowsMovim, this.data.columnsMovim, 'Resumo de Movimentações'); break;
                case 'graficoClasse': {
                    if (this.preferenceCustomizacao?.ResCart_ClasseIsHorizontal) {
                        const barHorizontalClasse = new GraficoBarraPDF(this.pageName, config, this.htmlElements['graficoClasse'], 'Alocação por Classe', false, 15);
                        component = await barHorizontalClasse.criarGrafico(this.docObj, true);
                        break;
                    } else {
                        const pizzaClasse = new GraficoPizzaPDF(this.pageName, config, this.htmlElements['graficoClasse'], 'Alocação por Classe', this.data.pieChartClassData, true);
                        component = await pizzaClasse.criarGrafico(this.docObj);
                        break;
                    }
                }
                case 'graficoCustodiante': {
                    if (this.preferenceCustomizacao?.ResCart_CustodianteIsHorizontal) {
                        const barHorizontalCustodiante = new GraficoBarraPDF(this.pageName, config, this.htmlElements['graficoCustodiante'], 'Alocação por Custodiante', false, 15);
                        component = await barHorizontalCustodiante.criarGrafico(this.docObj, true);
                        break;
                    } else {
                        const formatType = this.preferenceCustomizacao?.ResCart_graficoAlocacaoVolume ? FormatTypes.monetary_no_space : FormatTypes.percentage;
                        const pizzaCustodiante = new GraficoPizzaPDF(this.pageName, config, this.htmlElements['graficoCustodiante'], 'Alocação por Custodiante', this.data.pieChartCustodianteData, true, formatType, this.moneyPrefix);
                        component = await pizzaCustodiante.criarGrafico(this.docObj);
                        break;
                    }
                }
                case 'linhaRetorno': {
                    if(this.perfHistPDF){
                        component = await this.perfHistPDF?.getComponentGraficoLinhaRetorno(config, 8, 200, 55, 'left');
                    }
                }
            }
            if (component) {
                components.push(component);
            }
        }
        if(components.length === 1) return [];   // só possui o título, então não deve incluir
        return components;
    }

    /**
     * Cria os atributos da tabela para o jsPDF
     * @param config Configurações do componente
     * @param row Linha
     * @param col Coluna
     * @param title Titulo
     * @param columnStyleIndex (Default 0) Index do estilo aplicado no campo 'columnStyles'
     * @param didParseCell (Opcional) Função executada para todas as celulas da tabela
     * @param body (Opcional) Permite passar o corpo da tabela formatadao direto
     * @returns ComponentPDF Componente da tabela para o PDF
     */
    private createConfigTable(config: any, row: any, col: any, title: string, columnStyleIndex = 0, didParseCell?: any, body?: any): ComponentPDF | null {
        const titleConfig = this.docObj.criarTituloTabela(title);
        const tableOptions = {
            headStyles: { halign: 'right', textColor: this.docObj.theme.titleFontColor },
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            columnStyles: { [columnStyleIndex]: { halign: 'left' } },
            styles: {
                halign: 'right',
                minCellHeight: 7,
            },
            didParseCell,
            body: body || funcoesPDF.createTableBody(row, col),
            head: funcoesPDF.createTableHead(col),
        }
        return funcoesPDF.gerarTabela(config, this.pageName, tableOptions, titleConfig);
    }
}
