import { fetchCartExp } from './../api/cartExpAPI';
import { resetStateBuilder, IObjDatas } from './helperReducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAtivosAgrupados {
  nome_ativo: string;
  classe: string;
  subclasse: string;
  tipo_ativo: string;
  sbr_fim: string;
  percent_sbr: string;
  ticker: string;
}

export interface IInfo_ativo{
  alias: string;
  ativo: string;
  carteira_cv_ou_xml_explodido: string;
  eh_fundo: boolean;
  sbr_ativo_antes_explodir: number;
  tipo_explosao: string;
}

export interface IAviso {
  ativos_nao_existem_carteira: boolean;
  tem_mvCartExplodida_cadastrada: boolean;
  houve_explosao: boolean;
  explosao_xml_com_data_fim_alterada: boolean;
  tipo_de_explosao: boolean;
  data_fim_AAAAmmDD_cart_explodida: string;
  datas_disponiveis: string[];
  tem_explosao_disponivel: boolean;
  carteira_cv_nao_cotizada_ou_inexistente_em_listagem_de_carteira_a_ser_explodida: string[];
  infos_ativos_explodidos: IInfo_ativo[];
}

export interface ICartExpState {
  isLoaded: boolean;
  loading: boolean;
  dados_cart_explodida: IAtivosAgrupados[];
  aviso_carteira_explodida: IAviso | null;
  obj_datas: IObjDatas;
}

const initialState: ICartExpState = {
  isLoaded: false,
  loading: false,
  dados_cart_explodida: [],
  aviso_carteira_explodida: null,
  obj_datas: {
    data_ini: '',
    data_fim: '',
    data_ini2: '',
    datas_nao_uteis: '',
    data_mais_antiga_portfolio_AAAAmmDD: '',
    data_mais_recente_portfolio_AAAAmmDD: '',
  },
};

export const cartExpSlice = createSlice({
  name: 'cartExp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resetStateBuilder(builder, initialState);

    builder.addCase(fetchCartExp.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCartExp.fulfilled, (state, action) => {
      state.loading = false;
      state.isLoaded = true;
      state.dados_cart_explodida = action.payload.CartExplodida?.dados_cart_explodida;
      state.aviso_carteira_explodida = action.payload.CartExplodida?.aviso_carteira_explodida;
      state.obj_datas = action.payload.CartExplodida?.obj_datas;
    });
    builder.addCase(fetchCartExp.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default cartExpSlice.reducer;
