import { checkPeriodoPersonalizado } from './../helper';
import { AxiosError } from 'axios';
import { IPerfHistState, IRendimento } from '../reducers/perfHistSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import mockPerfHist from '../mock/perfHist.json';
import api from './index';

interface PerfHistAux extends IPerfHistState {
  grafico_pl: (number | string)[][];
  grafico_retornos: (number | string)[][];
  rendimento_12meses: { [periodo: string]: IRendimento[] };
}

interface PerfHistAPI {
  PerfHist: PerfHistAux;
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchPerfHist = createAsyncThunk<PerfHistAPI, void, { state: RootState }>(
  'perfHist/fetch',
  async (nothing: void, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockPerfHist;

    const perdiodoPersonalizado = checkPeriodoPersonalizado(
      thunkAPI.getState().preference?.params.periodo,
    );

    const periodo =
      thunkAPI.getState().preference.params.periodo === 'maximo'
        ? 'data_ini_acomp'
        : thunkAPI.getState().preference.params.periodo;

    const data = JSON.stringify({
      nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

      data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
      data_ini: perdiodoPersonalizado
        ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
        : periodo,
      abas: ['PerfHist'],
      benchmarksPerfHist: thunkAPI.getState().perfHist.selectedBenchmarks,
      recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
    });

    const token = thunkAPI.getState().preference?.token.value ?? '';
    try {
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);

interface PerfHistPerfAux {
  [periodo: string]: [
    {
      classe: string;
      perf: number;
      perf_subclasse?: [
        {
          subclasse: string;
          perf: number;
          perf_ativo: [{ ativo: string; perf: number }];
        },
      ];
    },
  ];
}

interface PerfHistPerfAPI {
  PerfHistPerf: PerfHistPerfAux;
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchPerfHistPerf = createAsyncThunk<PerfHistPerfAPI, any, { state: RootState }>(
  'perfHist/fetchPerfHistPerf',
  async (args, thunkAPI) => {
    const periodo =
      thunkAPI.getState().preference.params.periodo === 'maximo'
        ? 'data_ini_acomp'
        : thunkAPI.getState().preference.params.periodo;
    const perdiodoPersonalizado = checkPeriodoPersonalizado(
      thunkAPI.getState().preference?.params.periodo,
    );

    const data = JSON.stringify({
      nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

      data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
      data_ini: perdiodoPersonalizado
        ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
        : periodo,
      abas: ['PerfHistPerf'],
      mesPerfHist: args,
      recalcular: 0,
    });

    const token = thunkAPI.getState().preference?.token.value ?? '';

    try {
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );
      if (response.data.msg && response.data.codigo) return thunkAPI.rejectWithValue(response.data);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);
