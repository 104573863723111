type Action = 'desenhaTabela' | 'desenhaTexto' | 'desenhaGrafico' | 'desenhaHTML';

export interface IConfig {
    id: string;
    col: number;        // col igual do bootstrap (1-12)
    level: number;      // indica qual é a camada do PDF que o elemento está
    heightID?: string;   // Ficará posicionado embaixo da tabela com esse id
    posX: number;
    width: number;
    size?: number;      // tamanho que o componente vai ocupar no PDF. Utilizado para guardar o tamanho dos gráficos
}

/**
 * Modelo que guarda todas as informações e formatações
 * necessárias para que um componente seja desenhado no PDF
 */
export class ComponentPDF{
    action: Action;         // qual operação que será executada no PDF
    page: string;           // nome da página que está localizado
    options: any;           // Todas as informações do componente( ex: body, head, styles etc)
    config: IConfig;

    constructor(action: Action, page: string, options: any, config: IConfig){
        this.action = action;
        this.page = page;
        this.options = options;
        this.config = config;
    }
}