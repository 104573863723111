import { checkPeriodoPersonalizado } from './../helper';
import { AxiosError } from 'axios';
import { IFluxoAtivosState } from './../reducers/fluxoAtivosSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import mockFluxoAtivos from '../mock/fluxoAtivos.json';
import api from './index';

interface IFluxoAtivosAPI {
  FluxoAtivos: IFluxoAtivosState;
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchFluxoAtivos = createAsyncThunk<IFluxoAtivosAPI, void | string, { state: RootState }>(
  'fluxoAtivos/fetch',
  async (differentPeriod: void | string, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockFluxoAtivos;
    try {
      const perdiodoPersonalizado = checkPeriodoPersonalizado(
        thunkAPI.getState().preference?.params.periodo,
      );

      const periodo =
        thunkAPI.getState().preference.params.periodo === 'maximo'
          ? 'data_ini_acomp'
          : thunkAPI.getState().preference.params.periodo;

      const data = JSON.stringify({
        nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

        data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
        data_ini: differentPeriod ?? (perdiodoPersonalizado
          ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
          : periodo),
        abas: ['FluxoAtivos'],
        recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
      });

      const token = thunkAPI.getState().preference?.token.value ?? '';
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);
