import React, { useMemo } from 'react';
import { Modal, Grid } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { Container, Header, Disclaimer } from './style';
import { setModalDisclaimerActive } from '../../services/reducers/preferenceSlice';
import { useAppDispatch, useAppSelector } from '../../services/reduxHooks';

interface ModalProps {
  openModal: boolean;
}

export default function ModalDisclaimer({
  openModal,
}: ModalProps) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [preferenceCustomizacao, carteiraList, params] = useAppSelector((state) => [
    state.preference.preferenceStyle.customizacao,
    state.preference.carteiraList,
    state.preference.params,
  ]);

  const handleClose = (status: boolean) => {
    dispatch(setModalDisclaimerActive(status));
  }

  const disclaimer = useMemo(() => {
    let disclaimerLocal = preferenceCustomizacao?.disclaimer ?? null;
    if (disclaimerLocal) {
      const disclaimerCarteiraList = carteiraList.find((item) => item.nome_portfolio.toUpperCase() === params.carteira?.toUpperCase())?.disclaimer;

      // Se houver disclaimer na carteiraList, utiliza no lugar do disclaimer da preference_whitelabel
      if (disclaimerCarteiraList) {
        disclaimerLocal = disclaimerCarteiraList;
      }
    }
    return disclaimerLocal;
  }, [preferenceCustomizacao, carteiraList, params.carteira]);

  return (
    <Modal open={openModal}>
      <Container>
        <Header>
        <span style={{fontWeight: "bold"}}>Disclaimer</span>
          <Close
            onClick={() => handleClose(false)}
            sx={{
              cursor: 'pointer',
              width: 20,
              [`@media ${theme.device.mobileXL}`]: {
                width: 15,
              },
              [`@media ${theme.device.mobileM}`]: {
                width: 10,
              },
            }}
          />
        </Header>
        <Disclaimer>
            <span>{disclaimer}</span>
        </Disclaimer>
      </Container>
    </Modal>
  );
}