import React from 'react';
import { useTheme } from 'styled-components';
import { helperFormat, FormatTypes } from '../services/helper';
import { Box, Grid } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquareFull } from '@fortawesome/free-solid-svg-icons';

export const useFormatFunction = (
  value: any,
  isPercentToggle?: boolean,
  moneyPrefix?: string | null,
  valueType?: FormatTypes,
  decimals?: number,
) =>
  helperFormat(
    value[1] ? value[1] - value[0] : value,
    valueType ?? (isPercentToggle ? FormatTypes.percentage : FormatTypes.monetary_extense),
    decimals ?? 2,
    moneyPrefix,
  );

export function barTextPropertiesFunction(
  value: number | undefined,
  isNegative: boolean,
  data: number[] | undefined,
  isHorizontal: 'bar' | 'waterfall' | boolean | undefined,
) {
  let maiorBarra = 0;
  let menorBarra = 0;
  let percentBarra = 0;
  let numeroPositivos = 0;
  let numeroNegativos = 0;
  let numeroNulos = 0;

  data?.forEach((item) => {
    if (item > maiorBarra) {
      maiorBarra = item;
    }
    if (item < menorBarra) {
      menorBarra = item;
    }
    if (item > 0) {
      numeroPositivos += 1;
    } else if (item < 0) {
      numeroNegativos += 1;
    } else {
      numeroNulos += 1;
    }
  });

  if (typeof value == 'number') {
    if (maiorBarra != 0 && maiorBarra >= Math.abs(menorBarra)) {
      percentBarra = Math.abs(value / maiorBarra);
    } else if (menorBarra != 0 && maiorBarra < Math.abs(menorBarra)) {
      percentBarra = Math.abs(value / menorBarra);
    }
  } else if (value?.[1]) {
    if (maiorBarra != 0 && maiorBarra >= Math.abs(menorBarra)) {
      percentBarra = Math.abs(value[1] - value[0] / maiorBarra);
    } else if (menorBarra != 0 && maiorBarra < Math.abs(menorBarra)) {
      percentBarra = Math.abs(value[1] - value[0] / menorBarra);
    }
  }

  let outsideBar = true;
  let onlyBar = false;
  if (maiorBarra >= Math.abs(menorBarra)) {
    outsideBar = percentBarra < 0.8; //Se a barra for grande o suficiente o texto vai ficar dentro dela
    if (Math.abs(menorBarra / maiorBarra!) > 0.35) {
      outsideBar = percentBarra < 0.8 && value != menorBarra; //A maior barra do menor lado funciona similarmente
    }
  } else {
    outsideBar = percentBarra < 0.8;
    if (Math.abs(maiorBarra / menorBarra!) > 0.35) {
      outsideBar = percentBarra < 0.8 && value != maiorBarra;
    }
  }
  if (
    ((!isNegative && numeroPositivos == 1) || //Se só tiver uma barra de um lado, ou tiver espaço para o texto horizontal, aí o texto aparece normalmente
      (isNegative && numeroNegativos == 1) ||
      numeroNegativos + numeroPositivos + numeroNulos < 5) &&
    !isHorizontal
  ) {
    outsideBar = true;
    onlyBar = true;
  }

  return { outsideBar, onlyBar };
}

export const CustomLabelFunction = (
  moneyPrefix?: string | null,
  x?: number,
  y?: number,
  value?: any,
  width?: number,
  height?: number,
  isPercentToggle?: boolean,
  isBarChart?: boolean,
  dispatchPDF?: boolean,
  isMobile?: boolean,
  listaValores?: number[],
  isHorizontal?: boolean,
) => {
  const theme = useTheme();
  const screenWidth = window.innerWidth;
  let isNegative = false;
  if (typeof value == 'number') {
    isNegative = value! < 0;
  } else {
    isNegative = value[1] - value[0] < 0;
  }
  const { outsideBar, onlyBar } = barTextPropertiesFunction(
    value,
    isNegative,
    listaValores,
    isHorizontal,
  );

  function xLabelConfig() {
    if (isHorizontal && isBarChart && isMobile) return x! + width! + 20;
    if (isHorizontal && isBarChart && !isMobile) return x! + width! + 37;
    if (isHorizontal && !isBarChart && isMobile) return screenWidth / 1.37;
    if (isHorizontal && !isBarChart && !isMobile && isNegative) return x! + width! + 55;
    if (isHorizontal && !isBarChart && !isMobile && !isNegative) return x! + width! + 45;
    return x! + width! / 2;
  }

  function dyLabelConfig() {
    if (isHorizontal) return 0.5 * height!;
    if (isMobile && !onlyBar && !dispatchPDF) return 3.5;
    if (isNegative) return 16;
    if (!isNegative) return -5;
  }

  function dxLabelConfig() {
    if (isHorizontal && !isBarChart && isMobile) return 0;
    if (isHorizontal && !isBarChart && !isMobile) return -100;
    if (isMobile && !onlyBar && !dispatchPDF) {
      if (
        (outsideBar && isNegative && (!isHorizontal || !isBarChart)) ||
        (!outsideBar && !isNegative && (!isHorizontal || !isBarChart))
      )
        return -24;
      if (
        (outsideBar && !isNegative && (!isHorizontal || !isBarChart)) ||
        (!outsideBar && isNegative)
      )
        return 24;
    }
    return 0;
  }

  return (
    <text
      x={xLabelConfig()}
      y={y}
      dy={dyLabelConfig()}
      dx={dxLabelConfig()}
      fill={theme.fontColor}
      fontWeight={!dispatchPDF ? 'bold' : ''}
      fontSize={!dispatchPDF && isMobile ? 9 : ''}
      textAnchor="middle"
      transform={
        !dispatchPDF && isMobile && !onlyBar && !isHorizontal
          ? `rotate(270, ${x! + width! / 2}, ${y})`
          : ''
      }>
      {useFormatFunction(value?.[1] ? value[1] - value[0] : value, isPercentToggle, moneyPrefix)}
    </text>
  );
};

export const renderLegendFunction = (
  { payload }: any,
  isPercentToggle?: boolean,
  moneyPrefix?: string | null,
) => {
  const theme = useTheme();
  if (payload.length % 2 !== 0) {
    payload.push({ id: '', value: '' });
  }
  return (
    <Grid
      container
      style={{ marginTop: 16, fontSize: 12, height: 150, overflowY: 'auto' }}
      justifyContent="space-evenly">
      {payload.map((entry: any, index: any) => (
        <React.Fragment key={index}>
          {gridItem({
            index,
            id: entry.id,
            value: entry.value,
            color: theme.chartColors[index % theme.chartColors.length],
            isPercentToggle: isPercentToggle,
            moneyPrefix,
          })}
        </React.Fragment>
      ))}
    </Grid>
  );
};

const gridItem = ({
  index,
  id,
  value,
  color,
  isPercentToggle,
  moneyPrefix,
}: {
  index: number;
  id: string | undefined;
  value: string | number;
  color: string;
  isPercentToggle?: boolean;
  moneyPrefix?: string | null;
}) => {
  return (
    <Grid key={`item-${index}`} item xs={12} md={6} sx={{ whiteSpace: 'nowrap' }}>
      {id ? (
        <>
          <Grid container style={{ color: '#596579', fontWeight: 500, padding: '10px' }}>
            <Grid
              item
              xs={10}
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              <FontAwesomeIcon icon={faSquareFull as IconProp} style={{ color, marginRight: 4 }} />{' '}
              <span>{id}</span>
            </Grid>
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end">
                <b>{useFormatFunction(value, isPercentToggle, moneyPrefix)}</b>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

// Se poucos meses fazem parte do gráfico de linha e de área, o eixo X será mostrado com dias, ao invés de repetir os meses
export const xAxisRepeatsMonths = (data: any) => {
  const numMesesData = data.reduce((num: string[], item: any) => {
    if (!num.includes(item.name)) {
      num.push(item.name);
    }
    return num;
  }, []);
  return numMesesData.length < 8
}
