/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useState } from 'react';
import { Skeleton } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
  Cell,
  Legend,
  XAxisProps,
  YAxisProps,
} from 'recharts';
import { useTheme } from 'styled-components';
import { FormatTypes } from '../../services/helper';
import useIsMobile from '../../services/useIsMobile';
import {
  CustomLabelFunction,
  useFormatFunction,
  renderLegendFunction,
} from '../componentsFunctions';

interface IGraficoBarra {
  data: { name: string; value: number }[] | null;
  height?: number;
  onClickHandle?: (value: any) => void | null;
  valueType?: FormatTypes;
  decimals?: number;
  moneyPrefix?: string | null;
  colors: string | string[];
  hideXAxisLabels?: boolean;
  isPercentToggle?: boolean;
  dispatchPDF?: boolean; // considerar a posição do texto como se fosse tela grande
  isBarHorizontal?: boolean;
  handleOnAnimationEnd?: () => void;
  showAnimation?: boolean;
}

export const GraficoBarra = ({
  data,
  height,
  onClickHandle,
  colors,
  hideXAxisLabels,
  isPercentToggle,
  dispatchPDF = false,
  handleOnAnimationEnd,
  moneyPrefix,
  isBarHorizontal,
  showAnimation,
}: IGraficoBarra) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [isAnimation, setIsAnimation] = useState(true);

  const useFormat = (value: any) => useFormatFunction(value, isPercentToggle, moneyPrefix);
  const renderLegend = (value: any) => renderLegendFunction(value, isPercentToggle, moneyPrefix);

  const handleClick = useCallback((entry: any) => {
    if (onClickHandle) onClickHandle(entry);
  }, []);

  const listaBarra = data?.map((obj) => {
    return obj.value;
  });

  const CustomLabel = ({
    x,
    y,
    value,
    width,
    height,
    listaValores,
  }: {
    x?: number;
    y?: number;
    value?: number;
    width?: number;
    height?: number;
    listaValores?: number[];
  }) => {
    return CustomLabelFunction(
      moneyPrefix,
      x,
      y,
      value,
      width,
      height,
      isPercentToggle,
      true,
      dispatchPDF,
      isMobile,
      listaValores,
      isBarHorizontal,
    );
  };

  const handleAnimationEnd = () => {
    () => setIsAnimation(false);
    if (handleOnAnimationEnd) handleOnAnimationEnd();
  };

  let maxDataValue = 0;
  let minDataValue = 0;
  let tickIntervalBarChart = 0;

  if (data) {
    maxDataValue = Math.max(...data.map((o) => o.value));
    minDataValue = Math.min(...data.map((o) => (o.value <= 0 ? o.value : 0)));
    tickIntervalBarChart = Math.floor(maxDataValue / 4);
  }

  const xAxisConfig: XAxisProps = isBarHorizontal
    ? {
        type: 'number',
        axisLine: false,
        domain: [minDataValue, maxDataValue * 1.1],
        tickFormatter: useFormat,
        ticks: Array.from(
          { length: Math.floor((maxDataValue * 1.1) / tickIntervalBarChart) + 1 },
          (_, i) => i * tickIntervalBarChart,
        ),
      }
    : {
        tickLine: false,
        axisLine: false,
        dataKey: 'name',
        tick: hideXAxisLabels ? false : true,
        angle: 45,
        dx: 15,
        dy: 20,
        minTickGap: -200,
      };

  const yAxisConfig: YAxisProps = isBarHorizontal
    ? {
        type: 'category',
        dataKey: 'name',
        tick: {dx: dispatchPDF ? -15 : ''},
      }
    : {
        tickLine: false,
        axisLine: false,
        tick: { width: 80 },
        padding: { bottom: 24 },
        domain: data ? ['auto', Math.max(...data.map((o) => o.value)) * 1.1] : [],
        tickFormatter: useFormat,
      };

  const cartesianGridConfig = isBarHorizontal
    ? {
        horizontal: false,
        strokeDasharray: '5 2',
      }
    : {
        vertical: false,
        strokeDasharray: '1 0',
      };

  return data && data.length > 0 ? (
    <div style={{ marginTop: 50 }}>
      <ResponsiveContainer width="100%" height={height}>
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: isBarHorizontal ? 0 : 16,
            right: isBarHorizontal ? 40 : 32,
            left: isBarHorizontal ? 70 : 24,
            bottom: 32,
          }}
          layout={isBarHorizontal ? 'vertical' : 'horizontal'}
          onClick={(data) => {
            if (data && data.activeLabel && data.activePayload && data.activePayload.length > 0) {
              handleClick(isBarHorizontal ? data.activeLabel : data.activePayload[0]);
            }
          }}>
          <CartesianGrid {...cartesianGridConfig} />
          <XAxis {...xAxisConfig} />
          {hideXAxisLabels ? (
            <Legend
              payload={data.map((item, index) => ({
                id: item.name,
                type: 'square',
                value: item.value,
                color: theme.chartColors[index % theme.chartColors.length],
              }))}
              content={renderLegend}
              verticalAlign="bottom"
              style={{ height: '300px !important' }}
            />
          ) : null}
          <YAxis {...yAxisConfig} />
          <Tooltip
            wrapperStyle={{ outline: 'none' }}
            formatter={(value) => [useFormat(value), 'Valor']}
          />
          <ReferenceLine y={0} stroke={theme.fontColor} />
          <Bar
            dataKey="value"
            onAnimationEnd={handleAnimationEnd}
            isAnimationActive={showAnimation ? isAnimation : false}>
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                cursor="pointer"
                fill={typeof colors !== 'string' ? colors[index % colors.length] : colors}
              />
            ))}
            <LabelList
              dataKey="value"
              position="top"
              content={<CustomLabel listaValores={listaBarra} />}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <Skeleton variant="rectangular" height={height} width="100%" />
  );
};
