import { Grid } from '@mui/material';
import React from 'react';
import {
    FormatTypes,
} from '../../../../services/helper';

import { CardsPDF } from '../../../../components/Cards';
import { GraficoPizza } from '../../../../components/GraficoPizza';
import GraficoLinha from '../../../../components/GraficoLinha';
import GraficoArea from '../../../../components/GraficoArea';
import { GraficoBarra } from '../../../../components/GraficoBarra';
import { IPageData, IListaGraficos } from '../index';
import { IPreferenceCustomizacao } from '../../../../services/PreferenceStyleInterface/IPreferenceCustomizacao';

/**
 * Gera a lista com os elementos dos gráficos utilizados no PDF
 * @param pageData Dados das páginas
 * @param theme Tema
 * @param setIsLoadedGraficos Função para setar o estado que indica se todos os gráficos já carregaram
 * @param listaGraficos Lista dos gráficos utilizados no PDF(pode variar dependendo das customizações de cada cliente)
 * @param chartLoaded Guarda o nome do gráfico e se ele está carregado ou não
 * @param isGraficoLinhaFullSize Indica se o gráfico linha (Retorno acumulado) tem tamanho col 12
 * @param moneyPrefix Unidade monetária
 *
 * @returns Array com todos os gráficos do PDF
 */
export function gerarGraficos(pageData: IPageData, theme: any, setIsLoadedGraficos: React.Dispatch<React.SetStateAction<boolean>>, listaGraficos: IListaGraficos, chartLoaded: React.MutableRefObject<IListaGraficos>, preferenceCustomizacao: IPreferenceCustomizacao | null, moneyPrefix: string) {
    /**
     * Executa essa função no callback do recharts quando termina as animações do gráfico
     * @param nome_grafico Qual gráfico vai executar essa função
    */
    function handleGraficoAnimationEnd(nome_grafico: string) {
        chartLoaded.current[nome_grafico as keyof typeof chartLoaded.current] = true;
        const allLoaded = Object.values(chartLoaded.current).every(item => item); // verifica se todos os 'value' do objeto são true
        if (allLoaded) {
            setIsLoadedGraficos(true);
        }
    }

    const componentResCartClasse = preferenceCustomizacao?.ResCart_ClasseIsHorizontal ? (
        <Grid>
          <GraficoBarra
            data={pageData?.ResCart?.pieChartClassData}
            decimals={2}
            moneyPrefix={moneyPrefix}
            colors={theme.chartColors}
            height={400}
            isPercentToggle={true}
            isBarHorizontal={true}
            dispatchPDF={true}
            handleOnAnimationEnd={() => handleGraficoAnimationEnd('graficoClasse')}
            showAnimation={true}
          />
        </Grid>
      ) : (
        <GraficoPizza
          series={pageData?.ResCart?.pieChartClassData}
          height={450}
          handleAnimationEnd={() => handleGraficoAnimationEnd('graficoClasse')}
          dispatchPDF={true}
        />
      );
      const componentResCartCustodiante = preferenceCustomizacao?.ResCart_CustodianteIsHorizontal ? (
        <Grid>
          <GraficoBarra
            data={pageData?.ResCart?.pieChartCustodianteData}
            decimals={2}
            moneyPrefix={moneyPrefix}
            colors={theme.chartColors}
            height={400}
            isPercentToggle={true}
            isBarHorizontal={true}
            dispatchPDF={true}
            handleOnAnimationEnd={() => handleGraficoAnimationEnd('graficoCustodiante')}
            showAnimation={true}
          />
        </Grid>
      ) : (
        <GraficoPizza
          series={pageData?.ResCart?.pieChartCustodianteData}
          height={450}
          handleAnimationEnd={() => handleGraficoAnimationEnd('graficoCustodiante')}
          dispatchPDF={true}
        />
      );

      const graficos = [
        {
          name: 'cards',
          styleContainer: {
            fontFamily: 'OpenSans-Bold, OpenSans-Regular',
            letterSpacing: '0.03em',
            overflow: 'visible',
          },
          component: <CardsPDF cards={pageData?.ResCart?.cardsData} />,
          page: 'ResCart',
          type: 'html',
        },
        {
          name: 'graficoClasse',
          styleContainer: {
            width: preferenceCustomizacao?.ResCart_ClasseIsHorizontal ? 600 : 450,
            height: preferenceCustomizacao?.ResCart_ClasseIsHorizontal ? 600 : '',
          },
          component: componentResCartClasse,
          page: 'ResCart',
          type: 'svg',
        },
        {
          name: 'graficoCustodiante',
          styleContainer: {
            width: preferenceCustomizacao?.ResCart_CustodianteIsHorizontal ? 600 : 450,
            height: preferenceCustomizacao?.ResCart_CustodianteIsHorizontal ? 600 : '',
          },
          component: componentResCartCustodiante,
          page: 'ResCart',
          type: 'svg',
        },
        {
            name: 'linhaRetorno',
            styleContainer: { width: preferenceCustomizacao?.PerfHist_retornoAcumuladoTopo ? 1100 : 550, height: 300, fontSize: '16px' },
            component:
                <GraficoLinha
                    data={pageData?.PerfHist?.data}
                    tipo={FormatTypes.percentage}
                    assets={pageData?.PerfHist?.assetsApi ?? null}
                    moneyPrefix={moneyPrefix}
                    handleOnAnimationEnd={() => handleGraficoAnimationEnd("linhaRetorno")}
                    dispatchPDF={true}
                />,
            page: 'PerfHist',
            type: 'svg'
        },
        {
            name: 'areaPatrimonio',
            styleContainer: { width: 550, height: 300, fontSize: '16px' },
            component:
                <GraficoArea
                    data={pageData?.PerfHist?.dataEvol}
                    tipo={FormatTypes.monetary_extense}
                    assets={['Carteira_retorno']}
                    moneyPrefix={moneyPrefix}
                    handleOnAnimationEnd={() => handleGraficoAnimationEnd("areaPatrimonio")}
                    dispatchPDF={true}
                />,
            page: 'PerfHist',
            type: 'svg'
        },
        {
            name: 'barraFinanceiro',
            component: <Grid width={1200}><GraficoBarra
                data={pageData?.PerfHist?.barChartRend12Data}
                decimals={2}
                moneyPrefix={moneyPrefix}
                colors={theme.barChartColor}
                height={300}
                isPercentToggle={false}
                dispatchPDF={true}
                handleOnAnimationEnd={() => handleGraficoAnimationEnd("barraFinanceiro")}
                showAnimation={true}
            /></Grid>,
            page: 'PerfHist',
            type: 'svg'
        },
        {
            name: 'pizzaCartExp',
            styleContainer: { width: 450 },
            component: <GraficoPizza series={pageData?.CartExp?.pieChartClasseData} height={450} handleAnimationEnd={() => handleGraficoAnimationEnd('pizzaCartExp')} dispatchPDF={true} />,
            page: 'CartExp',
            type: 'svg'
        },
    ];

     // seleciona os elementos HTML e os gráficos listados no PDF
    const graficosFilter = graficos.filter((grafico) => grafico.type === 'html' || grafico.name in listaGraficos);

    return graficosFilter;
}
