import { docJSPDF } from '../docJSPDF';
import { ComponentPDF } from '../ComponentPDF';
import { GraficoPDF } from './GraficoPDF';

import * as funcoesPDF from '../../Biblioteca/FuncoesPDF'
import * as helperPDF from '../../Biblioteca/helperPDF'

/**
 * Gera um componente para o PDF do gráfico de linha ou area
 */
export class GraficoLinhaAreaPDF extends GraficoPDF {
    sizeRow: number;
    width: number;
    height: number;

    constructor(pageName: string, config: any, svg: any, title?: string, legendData?: any, sizeRow = 4, width = 105, height = 50) {
        super(pageName, config, svg, title, legendData);
        this.sizeRow = sizeRow;
        this.width = width;
        this.height = height;
    }

    /**
     * Gera o componente do gráfico de linha ou area
     * @param docObj PDF
     * @returns Promise<ComponentPDF | null> Componente do gráfico de linha ou area
     */
    async criarGrafico(docObj: docJSPDF, fontSize = 6, alignLegenda = 'center'): Promise<ComponentPDF | null> {
        // estilos aplicados para a cell do texto da legenda
        const styles = {
            halign: 'left', fontSize, minCellHeight: 0.1, valign: 'middle', cellWidth: 18
        }
        const body =  helperPDF.formatLegendData(this.legendData, docObj.theme.lineChartColors, styles, this.sizeRow);
        const legenda = this.createOptionsTableLegend(body);
        return await this.configurarGrafico(docObj, this.width, this.height, legenda, 'left', alignLegenda);
    }

    /**
     * Cria os atributos da legenda para o jsPDF
     * @param body Corpo
     * @returns Configurações da legenda
     */
    private createOptionsTableLegend(body: any) {
        const tableOptions = {
            body,
            head: [],
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            theme: 'plain',
            styles: {
                ...funcoesPDF.GLOBAL_STYLES,
                minCellHeight: 0.1,
                cellPadding: [1.5, 0]
            },
        }
        return tableOptions;
    }
}