import jsPDF from 'jspdf';

/**
 * Desenha a Contracapa do PDF
 * @param doc PDF
 * @param ContracapaPDF Imagem da Contracapa do PDF na base64
 */
export function desenharContracapaPDF(doc: jsPDF, ContracapaPDF: string) {
    doc.addPage();  // ir para uma página nova
    doc.addImage(ContracapaPDF || '', 'JPEG', 0, 0, 210, 300);
}