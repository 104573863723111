import React, { useEffect, useState } from 'react';
import { HeaderContainer } from '../../../components/Container';
import Titulo from '../../../components/Titulo';
import { CartCounter } from '../../../components/Titulo';
import { Line } from '../../../components/Line';
import {
  CardCarteiras,
  CodigoCarteira,
  ContainerWrapper,
  Data,
  Patrimonio,
  TituloCarteira,
} from './style';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import Autocomplete from './Autocomplete';
import { setCarteiraID, setData } from '../../../services/reducers/preferenceSlice';
import {resetSelectedBenchmarks as resetSelectedBenchmarksResumoCart} from '../../../services/reducers/resumoCartSlice';
import {resetSelectedBenchmarks as resetSelectedBenchmarksPerfHist} from '../../../services/reducers/perfHistSlice';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Grid, Skeleton } from '@mui/material';
import { checkPeriodoPersonalizado, FormatTypes, helperFormat } from '../../../services/helper';
import { fetchCarteiraList, sendAnalyticsData } from '../../../services/api/preferenceAPI';
import LoadingModal from '../LoadingModal';
import FixedLoadingButton from '../../../components/FixedLoadingButton';

export default function SelecionarCarteira() {
  const [carteiraPesquisada, setCarteiraPesquisada] = useState('');
  const navigate = useNavigate();
  const { state, search } = useLocation();
  const dispatch = useAppDispatch();
  const [carteiraList, params, loading, isLoadedCarteiraList, isLoadingMinimized] = useAppSelector((state) => [
    state.preference.carteiraList,
    state.preference.params,
    state.preference.loadingCarteiraList,
    state.preference.loadedCarteiraList,
    state.loadMinimized.isLoadingMinimized
  ]);

  useEffect(() => {
    document.title = `${(window as any).env.REACT_APP_TABNAME} - Selecione a carteira`;
  }, []);

  useEffect(() => {
    dispatch(sendAnalyticsData(location.href));
  }, [params]);

  useEffect(() => {
    if (carteiraList && carteiraList.length === 1) {
      dispatch(setCarteiraID(carteiraList[0].nome_portfolio));
      if (state) {
        navigate(`${state}?carteira=${carteiraList[0].nome_portfolio}`);
      } else navigate(`/resumo-da-carteira?carteira=${carteiraList[0].nome_portfolio}`);
    }
  }, [carteiraList]);

  useEffect(() => {
    async function loadList() {
      if(!isLoadedCarteiraList){    // utilizar sempre a mesma lista (exceção: se o usuário recalcular, alterar a lista)
        await dispatch(fetchCarteiraList());
      }
    }
    loadList();
  }, [isLoadedCarteiraList]);

  const getURL = (carteira: string) => {
    let periodo_url = params.periodo || 'maximo';
    if (carteira !== params.carteira) {
      if (checkPeriodoPersonalizado(params.periodo)){
        periodo_url = '12m';
      }
    }
    let url = '';
    if (state) {
      url = `${state}?carteira=${carteira}&periodo=${periodo_url}`;
    } else {
      url = `resumo-da-carteira?carteira=${carteira}&periodo=${periodo_url}`;
    }
    return url;
  };

  const handleCarteira = (e: any, carteira: string) => {
    if (!(e.metaKey || e.ctrlKey) && carteira !== params.carteira) { // se for control+click/cmd+click ignora, porque os dados da aba atual devem ser mantidos
      if (checkPeriodoPersonalizado(params.periodo)) {
        dispatch(setData('12m'));
      } else if (!params.periodo) {
        dispatch(setData('maximo'));
      }
      dispatch(setCarteiraID(carteira));
      // resetar os benchmarks
      dispatch(resetSelectedBenchmarksResumoCart());
      dispatch(resetSelectedBenchmarksPerfHist());
    }
  };

  function customSort(a: any, b: any) {
    // Sort entries with aliases before those without aliases
    if (a.alias_nome_portfolio && !b.alias_nome_portfolio) {
      return -1;
    } else if (!a.alias_nome_portfolio && b.alias_nome_portfolio) {
      return 1;
    } else if (a.alias_nome_portfolio && b.alias_nome_portfolio) {
      // If both have an alias, sort by alias first
      const aliasComparison = a.alias_nome_portfolio.localeCompare(b.alias_nome_portfolio);
      if (aliasComparison !== 0) {
        return aliasComparison;
      }
    }

    // Sort alphabetically by name
    return a.nome_portfolio.localeCompare(b.nome_portfolio);
  }

  return (
    <>
      <HeaderContainer>
        <Titulo>Selecione a carteira</Titulo>
        <Autocomplete
          carteiraPesquisada={carteiraPesquisada}
          setCarteiraPesquisada={setCarteiraPesquisada}
        />
      </HeaderContainer>
      <CartCounter mb="8px">
        {
          carteiraList.filter(
            (item) =>
              item.nome_portfolio.toLowerCase().includes(carteiraPesquisada.toLowerCase()) ||
              (item.alias_nome_portfolio &&
                item.alias_nome_portfolio.toLowerCase().includes(carteiraPesquisada.toLowerCase())),
          ).length
        }
        /{carteiraList.length} Carteiras
      </CartCounter>
      <Line />
      <ContainerWrapper container spacing={2}>
        {isLoadedCarteiraList && carteiraList.length <= 0 ? (
          <b>Nenhuma carteira foi liberada para este login, entre em contato com seu gestor.</b>
        ) : null}
        {isLoadedCarteiraList ? (
          carteiraList
            .slice()
            .sort(customSort)
            .map(
              (item, i) =>
                (item.nome_portfolio.toLowerCase().includes(carteiraPesquisada.toLowerCase()) ||
                  item.alias_nome_portfolio
                    ?.toLowerCase()
                    .includes(carteiraPesquisada.toLowerCase())) && (
                  <Grid item xs={12} sm={4} lg={3} key={item.nome_portfolio}>
                    <Link
                      to={getURL(item.nome_portfolio)}
                      onClick={(e) => handleCarteira(e, item.nome_portfolio)}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <CardCarteiras
                        key={item.nome_portfolio ?? i}>
                        <TituloCarteira>
                          {item.alias_nome_portfolio ?? item.nome_portfolio}
                        </TituloCarteira>
                        {item.alias_nome_portfolio ? (
                          <CodigoCarteira>{item.nome_portfolio}</CodigoCarteira>
                        ) : null}
                        <Patrimonio>
                          Patrimônio:{' '}
                          <span style={{ whiteSpace: 'nowrap' }}>
                            {item.pl_data_mais_recente
                              ? helperFormat(
                                  item.pl_data_mais_recente,
                                  FormatTypes.monetary,
                                  2,
                                  (item.unidade_monetaria === 'EUR' || item.moeda === '&#8364') ? '€' : item.moeda ?? '',
                                )
                              : ''}
                          </span>
                        </Patrimonio>
                        <Data>
                          {item.data_mais_recente_portfolio_AAAAmmDD
                            ? `(em ${item.data_mais_recente_portfolio_AAAAmmDD
                                .split('-')
                                .reverse()
                                .join('/')})`
                            : null}
                        </Data>
                      </CardCarteiras>
                    </Link>
                  </Grid>
                ),
            )
        ) : (
          <>
            <Grid item xs={12} sm={4} lg={3}>
              <Skeleton variant="rectangular" height={150} />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Skeleton variant="rectangular" height={150} />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Skeleton variant="rectangular" height={150} />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <Skeleton variant="rectangular" height={150} />
            </Grid>
          </>
        )}
      </ContainerWrapper>
      <LoadingModal loading={(loading && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={loading ?? false} isLoadingMinimized={isLoadingMinimized}/>
    </>
  );
}
