/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkPeriodoPersonalizado } from './../helper';
import { IPosConState } from '../reducers/posConSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import mockPosCon from '../mock/posCon.json';
import mockModalPoscon from '../mock/modalPosCon.json';
import api from './index';
import { AxiosError } from 'axios';

interface IPosConAPI {
  PosCon: IPosConState;
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}

export const fetchPosCon = createAsyncThunk<IPosConAPI, void, { state: RootState }>(
  'posCon/fetch',
  async (nothing: void, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockPosCon;

    const perdiodoPersonalizado = checkPeriodoPersonalizado(
      thunkAPI.getState().preference?.params.periodo,
    );

    const periodo =
      thunkAPI.getState().preference.params.periodo === 'maximo'
        ? 'data_ini_acomp'
        : thunkAPI.getState().preference.params.periodo;

    const data = JSON.stringify({
      nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

      data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
      data_ini: perdiodoPersonalizado
        ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
        : periodo,
      abas: ['PosCon'],
      recalcular: thunkAPI.getState().preference?.recalcular ? 1 : 0,
    });

    const token = thunkAPI.getState().preference?.token.value ?? '';

    try {
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );
      if (response.data.msg && response.data.codigo) return thunkAPI.rejectWithValue(response.data);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);

interface IModalPosConAPI {
  modais: { PosCon: (number | string)[][][] };
  access_token?: string;
  expires_in?: number;
  msg?: string;
  codigo?: string;
}
export const fetchModalPosCon = createAsyncThunk<IModalPosConAPI, any, { state: RootState }>(
  'posCon/fetchModal',
  async (args, thunkAPI) => {
    if (process.env.REACT_APP_MOCK_API === 'true') return mockModalPoscon;


    const periodo =
      thunkAPI.getState().preference.params.periodo === 'maximo'
        ? 'data_ini_acomp'
        : thunkAPI.getState().preference.params.periodo;
    const perdiodoPersonalizado = checkPeriodoPersonalizado(
      thunkAPI.getState().preference?.params.periodo,
    );

    const data = JSON.stringify({
      nome_portfolio: thunkAPI.getState().preference?.params.carteira ?? '',

      data_fim: perdiodoPersonalizado ? thunkAPI.getState().preference?.dateEnd : '31/12/9999',
      data_ini: perdiodoPersonalizado
        ? (thunkAPI.getState().preference?.dateStart === 'maximo' ? 'data_ini_acomp' : thunkAPI.getState().preference?.dateStart)
        : periodo,
      abas: ['PosCon'],
      modaisPosCon: [args],
      recalcular: 0,
    });

    const token = thunkAPI.getState().preference?.token.value ?? '';

    try {
      const response = await api(token).post(
        `API/EndPoint002.php?code=${(window as any).env.REACT_APP_APICODE}&service=CarregaCoreExtratoWhitelabel001`,
        data,
      );
      if (response.data.msg && response.data.codigo) return thunkAPI.rejectWithValue(response.data);
      return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return thunkAPI.rejectWithValue(err.response?.data);
    }
  },
);
