import autoTable, { CellInput, RowInput } from 'jspdf-autotable';
import {
    helperFormat,
    FormatTypes,
} from '../../../../services/helper';
import { IPreferenceCustomizacao } from '../../../../services/PreferenceStyleInterface/IPreferenceCustomizacao';
import { IListaGraficos, IPageData } from '../index';
import { IObjDatas } from '../../../../services/reducers/helperReducers';
import { DefaultTheme } from 'styled-components';
import { ICarteira } from '../../../../services/reducers/preferenceSlice';

/**
 * Gera uma lista com os gráficos que estarão no PDF e um valor para indicar se estão carregados ou não
 * @param pageData dados das páginas
 * @param abas Lista com as páginas do PDF
 * @param preferenceCustomizacao Customizações do cliente
 * @returns Objeto com os gráficos do PDF. A chave é o nome do gráfico e o valor indica se ele está carregado ou não
 */
export function listarGraficos(pageData: IPageData, abas: any, preferenceCustomizacao: IPreferenceCustomizacao | null) {
    const listaGraficos: IListaGraficos = {};
    if (abas.includes('ResCart')) {
        if (
          pageData?.ResCart?.pieChartClassData &&
          pageData.ResCart.pieChartClassData.length > 0 &&
          !isNaN(pageData.ResCart.pieChartClassData[0].value)
        ) {
            listaGraficos.graficoClasse = false;
        }
        if (
          (preferenceCustomizacao?.ResCart_mostrarGraficoAlocacao ?? true) &&
          pageData?.ResCart?.pieChartCustodianteData &&
          pageData.ResCart.pieChartCustodianteData.length > 0 &&
          !isNaN(pageData.ResCart.pieChartCustodianteData[0].value)
        ) {
            listaGraficos.graficoCustodiante = false;
        }
      }
    if (abas.includes('PerfHist')) {
        listaGraficos.linhaRetorno = false;
        if (!(preferenceCustomizacao?.PerfHist_removerGrafEvPatrimonio ?? false)) {
            listaGraficos.areaPatrimonio = false;
        }
        listaGraficos.barraFinanceiro = false;
    }
    if (abas.includes('CartExp')) {
        if(pageData.CartExp?.pieChartClasseData && pageData.CartExp.pieChartClasseData.length > 0 && !isNaN(pageData.CartExp.pieChartClasseData[0].value)){
            listaGraficos.pizzaCartExp = false;
        }
    }
    return listaGraficos;
}

/**
 * Obtem os elementos HTML/SVG de uma determinada página
 * @param graficos Array com todos os gráficos
 * @param containerRefs Referência para os gráficos HTML
 * @param nomePagina Página dos gráficos
 * @returns Objeto com os elementos HTML/SVG. A chave é o nome do gráfico
 */
export function getHTMLElements(graficos: any[], containerRefs: React.MutableRefObject<Record<string, HTMLDivElement | null>>, nomePagina: string) {
    return graficos.filter((grafico) => grafico.page === nomePagina)
        .reduce<Record<string, any>>((acc, grafico) => {
            acc[grafico.name] = grafico.type === "svg" ? getSvgFromRecharts(containerRefs.current[grafico.name]) : containerRefs.current[grafico.name];
            return acc;
        }, {});
}

/**
 * Obtem o elemento SVG do gráfico gerado pelo Recharts
 * @param el Elemento HTML
 * @returns SVG com a fonte-family Open Sans
 */
export function getSvgFromRecharts(el: HTMLDivElement | null) {
    if (el) {
        const svg = el.querySelector('svg');
        // adicionar fonte 'Open Sans' no elemento svg
        if (svg instanceof SVGElement) {
            const style = document.createElementNS('http://www.w3.org/2000/svg', 'style');
            style.textContent = `
              text {
                font-family: OpenSans-Regular, OpenSans-Bold;
                font-size:16px;
              }
            `;
            svg.appendChild(style);
        }
        return svg;
    } else {
        return null;
    }
}

/**
 * Formata as datas do PDF
 * @param obj_datas Datas
 * @returns Objeto com as datas formatadas
 */
export function generateDatasPDF(obj_datas: IObjDatas) {
    const datasIni = obj_datas.data_ini.split('/');
    const data_iniPDF = `_${datasIni[2]}${datasIni[1]}${datasIni[0]}`;
    const datasFim = obj_datas.data_fim.split('/');
    const data_fimPDF = `_${datasFim[2]}${datasFim[1]}${datasFim[0]}`;
    const data_analise = {
        ini: datasIni[0] + '/' + datasIni[1] + '/' + datasIni[2],
        fim: datasFim[0] + '/' + datasFim[1] + '/' + datasFim[2]
    }

    const dataAtual_timezone = new Date().toLocaleString("en-US", { timeZone: "America/Sao_Paulo" });
    const dataAtual = new Date(dataAtual_timezone);
    const anoElaboracao = dataAtual.getFullYear().toString();
    const mesElaboracao = (dataAtual.getMonth() + 1).toString().padStart(2, '0');
    const diaElaboracao = dataAtual.getDate().toString().padStart(2, '0');
    const dataElaboracao = '_' + anoElaboracao + mesElaboracao + diaElaboracao;
    const dataElaboracaoRodape = diaElaboracao + '/' + mesElaboracao + '/' + anoElaboracao;

    return {
        datasIni,
        datasFim,
        data_iniPDF,
        data_fimPDF,
        data_analise,
        dataElaboracao,
        dataElaboracaoRodape
    }
}

/**
 * Adiciona índice alfabetico no início de cada nome, no formato "A. nome"
 * @param data
 * @returns Dados com o campo name formatado
 */
export function addAlphaIndex(data: any) {
    return data.map((item: any, index: number) => ({
        ...item,
        name: String.fromCharCode(('A'.charCodeAt(0) + index%26)) + '. ' + item.name
    }));
}

/**
 * Gera a legenda dos gráficos
 * @param data Informações da legenda
 * @param arrayCores Cores utilizadas para cada elemento
 * @param styles Estilo do texto da legenda
 * @param sizeRow Quantidade de itens por linha
 * @param atributes Se a legenda possuir mais de um campo por item, é necessário passar quais são os atributos como array
 * @param moneyPrefix Unidade monetária. Utilizada no volume custodiante
 *
 * @returns Linhas da tabela para gerar a legenda
 */
export function formatLegendData(data: any[], arrayCores: any, styles: any, sizeRow: number, atributes?: [string, FormatTypes][], moneyPrefix?: string) {
    const rows = [];
    let rowTemp: CellInput[] = [];
    for (let i = 0; i < data.length; ++i) {
        // adiciona box com cor
        rowTemp.push({
            content: '',
            styles: {
                fillColor: data[i].color || arrayCores[i % arrayCores.length],
                lineColor: '#FFFFFF',
                cellWidth: 5.4,
                minCellHeight: 0.1,
                lineWidth: 2,
            },
        });
        if (atributes) {  // adiciona todos os atributos do item na linha
            for (const [atribute, formatTypes] of atributes) {
                if (atribute in data[i]) {
                    rowTemp.push({
                        content: helperFormat(data[i][atribute], formatTypes, 2, moneyPrefix),
                        styles: styles[atribute],
                    });
                }
            }
        } else {  // adiciona o elemento diretamente
            rowTemp.push({
                content: data[i],
                styles,
            });
        }
        if ((i+1) % sizeRow === 0 || i === data.length - 1) {  // adiciona {sizeRow} items por linha
            rows.push(rowTemp);
            rowTemp = [];
        }
    }
    return rows;
}

/**
 * Gera a tabela com um box lateral com cores
 * @param rows Linhas
 * @param cols Colunas (Utilizadas para determinar ordem dos elementos da linha)
 * @param theme Tema
 * @param cellWidth Tamanho da linha
 * @param rowSpan Quantas linhas o box lateral vai ocupar
 * @param contentAttribute Se o box lateral tiver um texto, essa variável indica qual é o atributo que contém o text
 * @param fillColor Cor do box lateral. Se não passaruma cor, utiliza o chartColors
 * @returns Body com box lateral colorido
 */
export function gerarBodyComCor(rows: any, cols: any, theme: DefaultTheme, cellWidth = 1, rowSpan = 1, contentAttribute?: string, fillColor?: string, generateStyle?: (cell: any) => any) {
    const rowsColor: any[] = [];
    const lineFillColor = (row: any) => {
      if(Object.hasOwn(row, 'colorIndex')){
        if (row.colorIndex === false) return '#D9D9D9';
        if (typeof row.colorIndex == 'number') return theme.chartColors[row.colorIndex % theme.chartColors.length];
      }
      return '';
    }

    for (let i = 0; i < rows.length; i++) {
        const rowTemp: any[] = [];
        const colorStyles = {
            valign: 'middle',
            halign: 'center',
            textColor: '#FFFFFF',
            lineColor: '#FFFFFF',
            lineWidth: 1,
            fillColor: fillColor ?? lineFillColor(rows[i]),
            cellWidth,
        }
        let text = '';
        if (contentAttribute) {
            text = rows[i][contentAttribute];
        }
        if (contentAttribute && text || !contentAttribute) {    // se possui texto no box color, ele não pode ser vazio
            rowTemp.push({
                content: text,
                rowSpan,
                styles: colorStyles,
            });
        }
        // utiliza as colunas para adicionar os campos na mesma ordem
        cols.forEach((col: any) => {
            let styles = {
              halign: col.align || 'left',
            }
            if (generateStyle) {
              styles = {
                ...styles,
                ...generateStyle(rows[i]),
              }
            }
            rowTemp.push({content: rows[i][col.id], styles});
        });
        rowsColor.push(rowTemp);
    }
    return rowsColor;
}

/**
 * Obtem qual é a unidade monetária da carteira
 * @param carteiraList Lista das carteiras do usuário
 * @param carteira Carteira da caonsulta
 * @returns Unidade monetária
 */
export function getMoneyPrefix(carteiraList: ICarteira[] | null, carteira: string | null){
    if (carteiraList && carteiraList[0] && carteira){
        const carteira_result = carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === carteira.toUpperCase());
        if(carteira_result[0]){
            if(carteira_result[0].unidade_monetaria === 'EUR' || carteira_result[0].moeda === '&#8364'){
                return "€";
            }else{
                return carteira_result[0].moeda;
            }
        }
    }
    return 'R$';
}
