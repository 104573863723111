import styled from 'styled-components';
import { Button, MenuItem } from '@mui/material';

export const StyledMenuItem = styled(MenuItem)`
  background-color: ${({ theme }) => theme.dropSelectorColor};
  color: ${({ theme }) => theme.backgroundColorSecondary};
  font-family: 'Open-sans';
  margin-top: -8;
  margin-bottom: -8;
  @media ${(props) => props.theme.device.mobileXL} {
    font-size: ${(props) => props.theme.smFontSize};
  }
`

export const StyledButton = styled(Button)`
  cursor: pointer;
  display: flex;
  padding: 8px 16px;
  margin-top: 5px;
  margin-bottom: 8px;
  margin-right: 10px;
  min-width: 10;
  align-self: center;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  color: ${({ theme }) => theme.dropSelectorColor} !important;
  background-color: ${({ theme }) => theme.backgroundColorSecondary} !important;
  filter: ${({ disabled }) => (disabled ? 'brightness(0.8)' : '')};
  @media ${(props) => props.theme.device.mobileXL} {
    font-size: ${(props) => props.theme.smFontSize};
  }
  @media ${(props) => props.theme.device.mobileM} {
    font-size: ${(props) => props.theme.xsFontSize};
  }
`;

export const StyledButtonMarcar = styled.span`
  cursor: pointer;
  display: flex;
  padding: 0px 4px;
  margin: 0 auto 8px auto;
  min-width: 10px;
  max-width: 100px;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  border-style: solid;
  border-width: 1px;
  color: ${({ theme }) => theme.dropSelectorColor} !important;
  background-color: ${({ theme }) => theme.backgroundColorSecondary} !important;
  font-size: ${(props) => props.theme.xsFontSize};
  height: 18px;
  @media ${(props) => props.theme.device.mobileXL} {
    height: 22px;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.dropSelectorColor} !important;
  margin-bottom: -10px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.dropSelectorColor};
  max-height: 70vh;
  overflow-y: auto;
`;

export const SelectColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ColumnTitle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  margin-left: 10px;
  color: white;
  font-weight: bold;
`;
