import '../style.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable, { CellInput, RowInput } from 'jspdf-autotable';
import 'svg2pdf.js'
import '../../../../assets/fonts/OpenSans-Regular-normal'
import '../../../../assets/fonts/OpenSans-Bold-bold'
import { ILayoutItem, layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';
import { ComponentPDF, IConfig } from '../Components/ComponentPDF'
import { docJSPDF } from '../Components/docJSPDF';

export interface ITitle {
    text: string,
    x: number,
    y?: number,
    fontSize?: number,
    fontColor?: string,
    marginBottom?: number
    options?: any,
}

export interface ISVG {
    html: SVGElement,
    x?: number,
    y?: number,
    width: number,
    height: number,
    marginBottom?: number,
}

export interface ITable {
    margin?: { left: number; },
    tableWidth?: number,
    startY?: number,
    options?: any,
    styles?: any,
    headStyles?: any,
    bodyStyles?: any,
    columnStyles?: any,
    didParseCell?: any,
    didDrawCell?: any,
    head?: any,
    body?: any,
    theme?: string,
}

export interface IHTML {
    element: any;           // elememto HTML
    x: number;
    y?: number;
    scale: number;
    windowWidth: number;
    windowHeight: number;
    marginBottom?: number;  // "tamanho" que vai ocupar no PDF
}

/** Estilos aplicados para todas as tabelas no campo 'styles' */
export const GLOBAL_STYLES = {
    overflow: 'linebreak',
    fontSize: 6,
    fillColor: '#FFFFFF',
    textColor: '#646e7b',
}

/**
 * Formata a coluna no formato do header da tabela do jsPDF.
 * Utiliza o atributo align para definir o haling da coluna. Por padrão, utiliza 'left'
 * @param column Array com as colunas da tabela
 * @returns Um array que contém um elemento, o head da tabela (Formatação necessária para o jsPDF)
 */
export function createTableHead(columns: any) {
    if(!columns) return null;
    const head: { content: string, styles: any }[] = [];

    columns.forEach((col: any) => {
        head.push({ content: col.label || '', styles: { halign: col.align || 'left' } });
    });

    return [head];
}

/**
 * Formata as linhas no formato da tabela do jsPDF.
 * Utiliza as colunas do header para definir quais os atributos utilizados. OBS: segue o align da coluna
 *
 * @param rows Array com as linhas da tabela
 * @param column Array com as colunas da tabela
 * @param styles Estilos adicionais
 * @param generateStyle Função para adicionar parâmetros no campo styles
 * @returns O body da tabela, com os estilos já definidos
 */
export function createTableBody(rows: any, columns: any, styles?: any, generateStyle?: (cell: any) => any) {
    if(!rows || !columns) return null;
    const body: any[][] = [];

    rows.forEach((rowObj: any) => {
        const formatedRow: any[] = [];
        if (generateStyle) {
            styles = generateStyle(rowObj);
        }
        columns.forEach((col: any) => {
            styles = {
                ...styles, halign: col.align || 'left'
            }
            formatedRow.push({ content: rowObj[col.id], styles });
        });
        body.push(formatedRow);
    });
    return body;
}

/**
 * Calcula qual a posição x no PDF em mm
 * @param widthPDF Tamanho horizontal do PDF
 * @param currentPos Posição x atual
 * @param colSize Quantas colunas ocupa
 * @param margin Espaçamento entre os elementos da mesma linha
 * @returns number Posição x
 */
function calcularPosX(widthPDF: number, currentPos: number, colSize: number, margin: number): number {
    /**
     * Posição atual + tamanho de cada coluna multiplicado pelo número de colunas + espaçamento lateral
     */
    return currentPos + (widthPDF / 12) * colSize + margin;
}

/**
 * Calcula a largura que ocupará
 * @param widthPDF Tamanho horizontal do PDF
 * @param colSize Quantas colunas ocupa
 * @param margin Espaçamento entre os elementos da mesma linha
 * @returns number largura
 */
function calcularWidth(widthPDF: number, colSize: number, margin: number): number {
    let width = widthPDF * (colSize / 12);
    if (colSize !== 12) { // não considera o margin se possui apenas um elemento
        width -= margin;
    }
    return width;
}

/**
 * Cria as configurações que serão utilizadas para criar o ComponentsPDF
 * @param widthPDF Largura total do PDF
 * @param component String com id OU objeto com informações passadas no layout
 * @param index Camada atual na página do PDF
 * @param posX posição x
 * @param colSize quantidade de colunas que ocupa (1-12)
 * @param margin Espaçamento lateral
 * @returns Objeto com as configurações do componente
 */
function formatComponent(widthPDF: number, component: ILayoutItem, index: number, posX: number, colSize = 12, margin = 0):IConfig {
    const col = component.col || colSize;
    return {
        id: component.id ?? component,
        col,
        width: component.width ?? calcularWidth(widthPDF, col, margin),
        heightID: component.heightID ?? '',
        level: index,
        posX,
    }
}

/**
 * Processa o layout da página, calculando: posição x, largura e em qual camada está dos componentes da página
 * @param layout Layout da página
 * @param widthPDF Largura total do PDF
 * @param margin
 * @returns Configurações do layout processado
 */
export function processaLayout(layout: layoutType, widthPDF: number, margin: number) {
    const page: IConfig[] = [];
    layout.forEach((row: any, index: number) => {
        let currentPosX = margin;
        if (Array.isArray(row)) {
            const colSize = 12 / row.length;        // atribui o tamanho das colunas igualmente entre os elementos
            const marginEachComponent = margin / row.length;    // adiciona espaçamento entre as tabelas
            row.forEach((item: ILayoutItem) => {
                const formatedComponent = formatComponent(widthPDF, item, index, currentPosX, colSize, marginEachComponent);    // mesmo index, porque estão as tabelas lado a lado
                currentPosX = calcularPosX(widthPDF, currentPosX, formatedComponent.col, marginEachComponent);
                page.push(formatedComponent);

            })
        } else {    // linha possui apenas um elemento. Este elemento é uma string ou um objeto
            const formatedComponent = formatComponent(widthPDF, row, index, currentPosX);
            page.push(formatedComponent);
        }
    });
    return page;
}

/**
 * Cria as configurações para desenhar a tabela. Pode conter titulo
 * @param config Configurações do componente no PDF
 * @param page Nome da página
 * @param tabela Configurações da tabela
 * @param title  Configurações do título
 * @returns ComponentPDF da tabela
 */
export function gerarTabela(config: any, page: string, tabela: ITable, title?: ITitle) {
    if (!tabela.body || tabela.body.length === 0) {
        return null;
    }
    const tabelaComponents: { titulo?: any, tabela?: any } = {};

    // gera as opções do título
    if (title) {
        title.x = config.posX
        tabelaComponents.titulo = title;
    }

    // gera as opções da tabela
    tabela.styles = {
        ...tabela.styles,
        ...GLOBAL_STYLES
    }
    tabelaComponents.tabela = {
        rowPageBreak: 'avoid',
        ...tabela,
        margin: { left: config.posX },
        tableWidth: config.width,
    }

    return new ComponentPDF('desenhaTabela', page, tabelaComponents, config);
}

/**
 * Cria o componente para os elementos do HTML
 * @param config Configurações do componente no PDF
 * @param page Nome da página
 * @param options Configurações do HTML no PDF
 * @returns ComponentPDF do HTML
 */
export function gerarHTML(config: any, page: string, options: IHTML): ComponentPDF | null {
    if (options.element) {
        return new ComponentPDF('desenhaHTML', page, options, config);
    } else {
        return null;
    }
}

/**
 * Cria as configurações para desenhar o Gráfico. Pode conter titulo e legenda
 * @param config Configurações do componente no PDF
 * @param page Nome da página
 * @param svg Opções da imagem
 * @param title Opções do titulo
 * @param legend Opções da tabela da legenda
 * @param alignTitle Indica o alinhamento do titulo
 * @param alignLegend Indica o alinhamento da legenda
 * @returns ComponentPDF com titulo, grafico e legenda
 */
export async function gerarGrafico(config: IConfig, page: string, svg: ISVG, title?: ITitle, legend?: ITable, alignTitle = 'center', alignLegend = 'center'): Promise<ComponentPDF | null> {
    if (!svg.html) {
        return null;
    }

    const optionsGrafico: { titulo?: ITitle, grafico?: ISVG, legenda?: ITable } = {};

    // gera as opções do título
    if (title && title.text !== '') {
        title.x = config.posX;                      // define posicao do titulo
        if (alignTitle === 'center') {              // centraliza o titulo
            title.options = { align: 'center' }
            title.x += config.width / 2;            // Posição X deve estar no meio
        }
        optionsGrafico.titulo = title;
    }
    // gera as opções do SVG
    svg.x = config.posX + (config.width - svg.width) / 2;   // centraliza o SVG
    optionsGrafico.grafico = svg;

    // gera a legenda(tabela) do SVG
    if (legend) {
        legend.tableWidth = legend.tableWidth ?? config.width * 0.7;     // dimuniu o tamanho para não ficar maior que o grafico
        legend.margin = { left: config.posX + 10};  // + 10 (valor arbitrário) para não ficar alinhado com o título e dar um espaçamento
        if (alignLegend === 'center') {             // cetraliza a legenda
            legend.margin = { left: config.posX + (config.width - legend.tableWidth) / 2 };
        }
        optionsGrafico.legenda = legend;
    }

    const component = new ComponentPDF('desenhaGrafico', page, optionsGrafico, config);
    component.config.size = await calculaTamanhoGrafico(component);

    return component;
}

/**
 * Calcular o tamanho do gráfico com a legenda e o titulo (se houver)
 * Como funciona: Desenha o gráfico em um PDF temporário para obter qual é o tamanho com a legenda
 *
 * @param component Contem o componente do grafico, pode conter o SVG, titulo e legenda
 *
 * @returns Promise<number> Altura total do grafico (incluindo titulo e legenda)
 */
export async function calculaTamanhoGrafico(component: ComponentPDF): Promise<number> {
    const tempDoc = new jsPDF();
    const marginBottomGraficoTemp = component.options.grafico.marginBottom;
    component.options.grafico.marginBottom = 0;
    const [numberOfPages, newHeight] = await desenhaGraficos(tempDoc, component, 0, 0, null);
    component.options.grafico.marginBottom = marginBottomGraficoTemp;
    return newHeight;
}

/**
 * Cria componentPDF do titulo das páginas
 *
 * @param title configurações do título
 * @param page Nome da página
 * @param config Configuração do componente
 * @returns ComponentPDF Componente do título
 */
export function gerarTexto(title: ITitle, page: string, config?: any): ComponentPDF {
    return new ComponentPDF('desenhaTexto', page, title, config);
}

/**
 * Desenha Título  no PDF
 * @param doc PDF
 * @param title Componente do título
 * @param height Altura atual do PDF
 * @returns Altura que terminou o título
 */
function desenhaTexto(doc: jsPDF, title: ITitle, height: number) {
    const initialFontSize = doc.getFontSize();
    const initialTextColor = doc.getTextColor();
    if (title.fontSize) {
        doc.setFontSize(title.fontSize);
    }
    if (title.fontColor) {
        doc.setTextColor(title.fontColor);
    }
    doc.text(title.text, title.x, height, title.options);

    doc.setFontSize(initialFontSize);
    doc.setTextColor(initialTextColor);

    const newHeight = height + (title.marginBottom ? title.marginBottom : doc.getTextDimensions(title.text).h);
    return newHeight;
}

/**
 * Desenha HTML no PDF
 * @param doc PDF
 * @param component Componente do HTML
 * @param height Altura atual do PDF
 * @returns Altura que terminou a tabela
 */
export async function desenhaHTML(doc: jsPDF, component: ComponentPDF, height: number) {
    const htmlPDFElement = component.options;
    // A biblioteca do SVG considera o tamanho total do PDF, não o da página
    htmlPDFElement.y = height + doc.internal.pageSize.getHeight() * (doc.getNumberOfPages() - 1); // -1 para não contar a página atual
    await doc.html(htmlPDFElement.element, {
        x: htmlPDFElement.x,
        y: htmlPDFElement.y,
        html2canvas: {
            scale: htmlPDFElement.scale,
            windowWidth: htmlPDFElement.windowWidth,
            windowHeight: htmlPDFElement.windowHeight
        }
    });
    return height + (htmlPDFElement.marginBottom ?? 0);
}

/**
 * Desenha tabela no PDF
 * @param doc PDF
 * @param component Tabela
 * @param height Altura atual do PDF
 * @param marginBottomTable Espaçamento para baixo da tabela. Adiciona na altura final
 * @param storedHeights Mapa que guarda todas as alturas
 * @returns  [number, number] Array com a página e altura que terminou a tabela
 */
function desenhaTabela(doc: jsPDF, component: ComponentPDF | any, height: number, marginBottomTable: number, storedHeights?: Map<string, { pageNumber: number, pageHeight: number }>): [number, number] {
    const currentPageNumber = doc.getNumberOfPages();

    const titulo = component.options.titulo ?? null;
    const tabela = component.options.tabela;

    if (tabela.body.length === 0) {   // ignora tabela se ela está vazia
        return [currentPageNumber, height];
    }

    // desenha titulo da tabela
    if (titulo) {
        height = desenhaTexto(doc, titulo, height);
    }

    // desenha tabela
    autoTable(doc, { ...tabela, startY: tabela.startY || height });
    const pageHeight = (doc as any).lastAutoTable.finalY + marginBottomTable;
    const pageNumber = doc.getCurrentPageInfo().pageNumber;
    if (component.config && storedHeights) {
        storedHeights.set(component.config.id, { pageNumber, pageHeight });
    }
    return [pageNumber, pageHeight];
}

/**
 * Adciona um gráfico no PDF. Esse gráfico pode conter título e legenda
 * @param doc PDF
 * @param component Imagem
 * @param height Altura atual do PDF
 * @param marginBottomTable Margem inferior para legenda
 * @param storedHeights Mapa que guarda todas as alturas
 *
 * @returns Promise<[number, number]> Array com a página e altura que terminou o gráfico
 */
async function desenhaGraficos(doc: jsPDF, component: ComponentPDF, height: number, marginBottomTable: number, storedHeights?: Map<string, { pageNumber: number, pageHeight: number }> | null): Promise<[number, number]> {
    const titulo = component.options.titulo;
    const grafico = component.options.grafico;
    const legenda = component.options.legenda;

    // desenha titulo do grafico
    if (titulo) {
        height = desenhaTexto(doc, titulo, height);
    }

    // desenha SVG do grafico
    grafico.y = height;
    await doc.svg(grafico.html, grafico);
    height += grafico.height;

    let pageNumber;
    let newHeight;
    // desenha legenda do grafico
    if (legenda) {
        legenda.tableWidth = legenda.tableWidth ?? component.config?.width;
        [pageNumber, newHeight] = desenhaTabela(doc, { options: { tabela: legenda } }, height, marginBottomTable);
    } else {
        pageNumber = doc.getCurrentPageInfo().pageNumber;
        newHeight = height + (grafico.marginBottom ?? 0);
    }
    if (component.config && storedHeights) {
        storedHeights.set(component.config.id, { pageNumber, pageHeight: newHeight });
    }
    return [pageNumber, newHeight];
}

/**
 * Processa os componentes e desenha no PDF
 * @param docObj PDF
 * @param components Lista de componentes do PDF. É um array com as páginas. Cada página é um array com seus componentes
 */
export async function desenhaPDF(docObj: docJSPDF, components: ComponentPDF[][]) {
    /** Agrupa os elementos por level */
    const groupBy = (array: any, key: any) => {
        return array.reduce((result: any, component: any) => {
            const groupKey = component.config?.[key];
            (result[groupKey] = result[groupKey] || []).push(component);
            return result;
        }, {});
    };

    /** Utiliza como chave o id do componente. Guarda a altura e a página que o componente terminou */
    const storedHeights = new Map();

    // altura inicial
    let currentHeight = docObj.marginTopPage;

    for (const page of components) {
        // separa a página por level
        const groupedData = groupBy(page, "level");

        for (const level in groupedData) {
            if (!groupedData[level].length) continue;

            // obtem qual é a maior altura do level. Se não tiver as alturas calculadas, utiliza o espaço mínimo pré definido das tabelas
            const maxSizeComponents = groupedData[level].reduce((max: number, component: any) => Math.max(component.config?.size || 0, max), 0) || docObj.MIN_TABLE_SPACE;
            if (currentHeight + maxSizeComponents > docObj.heightPDF) {    // Verifica se há espaço suficiente na página atual
                docObj.doc.addPage();
                currentHeight = docObj.marginTopPage;
            }

            // todos os elementos do mesmo level devem começar na mesma página
            const startingPage = docObj.doc.getNumberOfPages();

            // guarda qual é a última página
            let maxPageNumber = 0;
            // guarda qual é a altura final do level
            let maxHeight = 0;

            for (const component of groupedData[level]) {
                if (component.config?.heightID) {   // verifica se utiliza uma posição relativa à outra tabela
                    const newHeight = storedHeights.get(component.config?.heightID);
                    if (newHeight && newHeight.pageHeight + maxSizeComponents < docObj.heightPDF) {    // Verifica se há espaço suficiente, se não houver, segue a última altura
                        currentHeight = newHeight.pageHeight
                        docObj.doc.setPage(newHeight.pageNumber);
                    }else{
                        docObj.doc.setPage(startingPage);
                        currentHeight = docObj.marginTopPage;
                    }
                }else{
                    docObj.doc.setPage(startingPage);   // todos do mesmo level devem começar na mesma página
                }
                /** guarda a altura do componente */
                let newHeight = 0;
                /** guarda a página que terminou o componente */
                let newPageNumber = 0;

                switch (component.action) {
                    case 'desenhaTexto': currentHeight = newHeight = desenhaTexto(docObj.doc, component.options, currentHeight); break;
                    case 'desenhaHTML': newHeight = await desenhaHTML(docObj.doc, component, currentHeight); break;
                    case 'desenhaTabela': [newPageNumber, newHeight] = desenhaTabela(docObj.doc, component, currentHeight, docObj.marginBottomTable, storedHeights); break;
                    case 'desenhaGrafico': [newPageNumber, newHeight] = await desenhaGraficos(docObj.doc, component, currentHeight, docObj.marginBottomTable, storedHeights); break;
                }
                if (newPageNumber > maxPageNumber) {    // se o componente terminar após a última página, ele é o final do level
                    maxPageNumber = newPageNumber;
                    maxHeight = newHeight;
                } else if(newPageNumber === maxPageNumber){    // se estão na mesma página, deve pegar a maior altura
                    maxHeight = Math.max(maxHeight, newHeight);
                }
            }
            // a altura atual recebe a altura final do level
            currentHeight = maxHeight;
        }
    }
}
