import jsPDF from "jspdf";
import { DefaultTheme } from 'styled-components';

/**
 * Documento jsPDF com configurações de estilo
 */
export class docJSPDF {
    readonly doc: jsPDF;
    readonly theme: DefaultTheme
    readonly width: number;
    readonly heightPDF: number;                 // Altura disponível da página (disponível porque considera as margens e espaçamento)
    readonly footerTopo: boolean;

    marginTopPage = 15;                         // Espaço entre início da página e topo
    marginBottomTable = 7;                     // Espaço após desenhar uma tabela
    marginBottomTitle = 5;                     // Espaço entre título da página e conteúdo
    marginBottomTitleTable = 3;                 // Espaço entre título e tabela
    marginBottomTitleGraph = 5;                 // Espaço entre título e gráfico
    styleTitle: any;                            // Estilo dos títulos das páginas
    styleTitleTable: any;                       // Estilo dos títulos das tabelas
    styleTitleGraph: any;                       // Estilo dos títulos dos gráficos

    readonly MIN_TABLE_SPACE = 35;              // valor para deixar a tabela com pelo menos 3 elementos
    readonly MARGIN_LEFT_RIGHT = 5;             // valor default para margin das tabelas da biblioteca autoTable
    private readonly MARGIN_TABLE = 10;         // valor default para margin das tabelas da biblioteca autoTable
    private readonly MARGIN_FOOTER_TOP = 8;     // aumenta spacing-top caso o footer esteja no topo da página
    private readonly FOOTER_SIZE = 12;          // tamanho do footer

    constructor(theme: DefaultTheme, footerTopo = false) {
        this.theme = theme;
        this.doc = new jsPDF();
        this.width = this.doc.internal.pageSize.getWidth() - 2 * this.MARGIN_LEFT_RIGHT;
        this.heightPDF = this.doc.internal.pageSize.getHeight() - 2 * this.MARGIN_TABLE;
        this.footerTopo = footerTopo;
        if(this.footerTopo){
            this.marginTopPage += this.MARGIN_FOOTER_TOP;   // aumenta o espaço entre o ínicio da página do PDF e os elementos para caber o footer no topo
        }
    }

    /**
     * Utilizar estilos pré definidos para os textos do PDF
     */
    useDefaultTitleStyles() {
        this.styleTitle = {
            x: 5,
            fontSize: 14,
            fontColor: this.theme.titleFontColor,
            marginBottom: this.marginBottomTitle,
        }
        this.styleTitleTable = {
            fontSize: 8,
            fontColor: this.theme.titleFontColor,
            marginBottom: this.marginBottomTitleTable,
        }
        this.styleTitleGraph = {
            fontSize: 8,
            fontColor: this.theme.titleFontColor,
            marginBottom: this.marginBottomTitleGraph,
        }
    }

    /**
     * Gera o título das páginas com seus estilos
     * @param text Título da página
     * @param extraOptions (Opcional) Opções extras de customização
     * @returns Título com options do jsPDF
     */
    criarTituloPagina(text: string, extraOptions?: any) {
        return {
            text: text,
            x: 5,
            ...this.styleTitle,
            ...extraOptions,
        }
    }

    /**
     * Gera o título da tabela com seus estilos
     * @param text Título da tabela
     * @param extraOptions (Opcional) Opções extras de customização
     * @returns Título com options do jsPDF
     */
    criarTituloTabela(text: string) {
        return {
            text: text,
            ...this.styleTitleTable
        }
    }

    /**
     * Gera o título do gráfico com seus estilos
     * @param text Título do gráfico
     * @param extraOptions (Opcional) Opções extras de customização
     * @returns Título com options do jsPDF
     */
    criarTituloGrafico(text: string) {
        return {
            text: text,
            ...this.styleTitleGraph
        }
    }
  }