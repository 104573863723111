import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { FormControl, Select, Checkbox, ListItemText, Grid } from '@mui/material';
import { Container } from '../Container';
import { ButtonWrapper, StyledButton, ColumnTitle, StyledButtonMarcar, StyledMenuItem } from './style';
import useIsMobile from '../../services/useIsMobile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../services/reduxHooks';

type OnSelect = (item: string[], option?: boolean) => void;
type OptionNameLabel<T> = (item: T, option?: "name" | "label") => string;

interface IProps<T> {
  title: string;
  selected: T[] | null;
  options: T[];
  IconComponent?: (style: { [key: string]: any }, open: boolean) => React.ReactNode;
  onSelect: OnSelect;
  optionNameLabel: OptionNameLabel<T>;
  secondColumnSeparator?: number;
  column1Title?: string;
  column2Title?: string;
  botoesMarcarDesmarcar?: boolean;
}

export default function Dropdown<T>(props: IProps<T>) {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const [openSelect, setOpenSelect] = useState(false);
  const [localSelected, setLocalSelected] = useState<string[]>([]);
  const columnIndex = props.secondColumnSeparator ?? Math.ceil(props.options.length / 2);

  useEffect(() => {
    const selectedArr = props.selected && props.selected.length > 0 ? props.selected?.map((item) => props.optionNameLabel(item, "name")) : [];
    setLocalSelected(selectedArr);
  }, [props.selected])

  const [isLoadingMinimized] = useAppSelector((state) => [
    state.loadMinimized.isLoadingMinimized,
  ]);

  const handleClick = () => {
    props.onSelect([...localSelected], false);
    setOpenSelect(false);
  };

  const clickOnBench = (bench: string) => {
    if (localSelected.includes(bench)) {
      const arr = [...localSelected];
      arr.splice(localSelected.indexOf(bench), 1);
      setLocalSelected(arr);
    } else {
      setLocalSelected([...localSelected, bench]);
    }
  };

  const avoidPropagation = (e: React.MouseEvent<HTMLElement>) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    } else if (window.event) {
      window.event.cancelBubble = true;
    }
  };

  const MarcarDesmarcar = (propsMarcar: { column: 1 | 2; }) => {
    const sliceStart = propsMarcar.column === 1 ? 0 : columnIndex;
    const sliceEnd = propsMarcar.column === 1 ? columnIndex : props.options.length;
    let marcar = true;
    props.options.slice(sliceStart, sliceEnd).forEach((option) => {
      if (localSelected.includes(props.optionNameLabel(option, "name"))) {
        marcar = false
      }
    })
    const marcaDesmarcaTodos = () => {
      const arr = [...localSelected];
      props.options.slice(sliceStart, sliceEnd).forEach((option) => {
        if (!marcar && localSelected.includes(props.optionNameLabel(option, "name"))) {
          arr.splice(arr.indexOf(props.optionNameLabel(option, "name")), 1);
        }
        if (marcar && !localSelected.includes(props.optionNameLabel(option, "name"))) {
          arr.push(props.optionNameLabel(option, "name"))
        }
      })
      setLocalSelected(arr);
    }
    return (<StyledButtonMarcar onClick={marcaDesmarcaTodos}>{marcar ? "Marcar" : "Desmarcar"} Todos</StyledButtonMarcar>);
  }

  const iconProps = {
    color: (isLoadingMinimized || props.options.length === 0) ? theme.disabledColor : theme.iconsColor,
    paddingBottom: openSelect ? "0" : "8px",
    paddingTop: openSelect ? "8px" : "0",
    cursor: "pointer",
  }

  const MenuItems = ({ sliceStart, sliceEnd }: { sliceStart: number; sliceEnd: number; }) => {
    return (
      <>
        {props.options
          .slice(sliceStart, sliceEnd)
          .map((item) => (
            <StyledMenuItem
              key={props.optionNameLabel(item, "label")}
              value={props.optionNameLabel(item, "name")}
              onClick={() => {
                clickOnBench(props.optionNameLabel(item, "name"));
              }}>
              <Checkbox
                checked={localSelected.includes(props.optionNameLabel(item, "name"))}
                icon={
                  <FontAwesomeIcon
                    icon={faSquare as IconProp}
                    style={{ color: theme.backgroundColorSecondary }}
                    fontSize={'1.5rem'}
                  />
                }
                checkedIcon={
                  <FontAwesomeIcon
                    icon={faSquareCheck as IconProp}
                    style={{ color: theme.backgroundColorSecondary }}
                    fontSize={'1.5rem'}
                  />
                }
              />
              <ListItemText primary={props.optionNameLabel(item, "label")} />
            </StyledMenuItem>
          ))}
      </>)
  }

  const FirstHeader = () => {
    return (
      <>
        {props.column1Title ? (<ColumnTitle>{props.column1Title}</ColumnTitle>) : null}
        {props.botoesMarcarDesmarcar ? (<MarcarDesmarcar column={1} />) : null}
      </>
    )
  }

  const SecondHeader = () => {
    return (
      <>
        {props.column2Title ? (<ColumnTitle>{props.column2Title}</ColumnTitle>) : null}
        {props.botoesMarcarDesmarcar ? (<MarcarDesmarcar column={2} />) : null}
      </>
    )
  }

  const DropdownGrid = ({maxHeight}: {maxHeight: string} ) => (
    <Grid>
      <Grid container>
        <Grid item xs={6}>
          <FirstHeader />
        </Grid>
        <Grid item xs={6}>
          <SecondHeader />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} style={{ overflowY: 'auto', maxHeight }}>
          <MenuItems sliceStart={0} sliceEnd={columnIndex} />
        </Grid>
        <Grid item xs={6} style={{ overflowY: 'auto', maxHeight }}>
          <MenuItems sliceStart={columnIndex} sliceEnd={props.options.length} />
        </Grid>
      </Grid>
    </Grid>
  )

  return (
    <Container>
      <FormControl variant="standard" style={{ display: 'flex', flexDirection: 'row' }}>
        <Select
          value={[]}
          disabled={isLoadingMinimized || props.options.length === 0}
          id="demo-multiple-checkbox-label"
          multiple
          MenuProps={{
            PaperProps: {
              style: {
                overflowY: 'hidden',
                backgroundColor: theme.dropSelectorColor,
              },
            },
          }}
          open={openSelect}
          onClick={() => {
            if (!isLoadingMinimized && props.options.length !== 0) {  // n�o permitir utilizar bot�o quando minizar o modal de loading
              setOpenSelect(!openSelect);
            }
          }}
          displayEmpty
          renderValue={() => {
            return <div style={{ color: theme.titleFontColor, paddingLeft: 2 }}>{props.title}</div>;
          }}
          inputProps={{
            'aria-label': 'Without label',
            IconComponent: () => props.IconComponent ? props.IconComponent(iconProps, openSelect) : <FontAwesomeIcon
              icon={(openSelect ? faSortUp : faSortDown) as IconProp}
              style={{
                color: iconProps.color,
                paddingBottom: iconProps.paddingBottom,
                paddingTop: iconProps.paddingTop,
                cursor: iconProps.cursor,
              }}
              fontSize={'1rem'}
            />,
          }}>
          <Grid
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              avoidPropagation(e);
              setOpenSelect(true);
            }}
            container
            style={{ maxHeight: '77vh' }}
          >
            <DropdownGrid maxHeight={isMobile ? '68vh' : '50vh'} />
          </Grid>
          <ButtonWrapper>
            <StyledButton
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                avoidPropagation(e);
                handleClick();
              }}>
              Aplicar
            </StyledButton>
          </ButtonWrapper>
        </Select>
      </FormControl>
    </Container>
  );
}