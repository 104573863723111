import React from 'react';
import TrocarCarteira from '../TrocarCarteira';
import BotaoGerarPDF from '../BotaoGerarPDF';
import Breadcrumb from '../Breadcrumb';
import { ContainerAux } from './style';
import { HeaderContainer } from '../Container';
import Titulo, { SmallerTitle } from '../Titulo';
import Periodo from '../Periodo';
import DataFim from '../Periodo/DataFim';
import Instituicao from '../Instituicao';
import Benchmarks from '../Benchmarks';
import { Grid } from '@mui/material';
import { IObjDatas } from '../../services/reducers/helperReducers';

interface IHeader {
  title: string;
  containerAux?: boolean;
  instituicao?: boolean;
  periodo?: boolean;
  periodoProps?: {
    options: string[];
    initialOption: string;
    loadingOptions: boolean;
    handleSelect: (value: string) => void;
  };
  dataFim?: boolean;
  benchmarks?: {
    selectedBenchmarks: string[] | null;
    dispatchSelectedBenchmarks: (newBenchmarks: string[]) => any;
  };
  obj_datas: IObjDatas;
  data_fim_apenas?: boolean;
  subtitle_date?: boolean;
  filtroTipo?: () => React.ReactNode;
}

export default function Header(props: IHeader) {
  const PeriodoComponent = () => (props.periodo ? <Periodo obj_datas={props.obj_datas} /> : (props.dataFim ? <DataFim obj_datas={props.obj_datas} /> : null));
  const InstComponent = () =>
    props.instituicao && props.periodoProps ? (
      <Instituicao
        options={props.periodoProps?.options}
        initialOption={props.periodoProps?.initialOption}
        loadingOptions={props.periodoProps?.loadingOptions}
        handleSelect={props.periodoProps?.handleSelect}
      />
    ) : null;
  const BenchComponent = () =>
    props.benchmarks ? (
      <Benchmarks
        selectedBenchmarks={props.benchmarks.selectedBenchmarks}
        dispatchSelectedBenchmarks={props.benchmarks.dispatchSelectedBenchmarks}
      />
    ) : null;
  return (
    <>
      <HeaderContainer>
        <Breadcrumb screen={props.title} />
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gridTemplateColumns: '1fr 1fr',
            gridGap: '5px',
          }}>
          <TrocarCarteira />
          <BotaoGerarPDF />
        </Grid>
      </HeaderContainer>
      <HeaderContainer>
        <Titulo>{props.title}</Titulo>
        {props.containerAux ? (
          <ContainerAux>
            <Grid container spacing={2}>
              <Grid item sx={{ marginLeft: props.benchmarks ? 0 : -2 }}>
                {<BenchComponent />}
              </Grid>
              <Grid item sx={{ marginLeft: props.filtroTipo ? 0 : -2 }}>
                {props.filtroTipo ? props.filtroTipo() : null}
              </Grid>
              <Grid item sx={{ marginLeft: props.instituicao ? 0 : -2 }}>
                {<InstComponent />}
              </Grid>
              <Grid item sx={{ marginLeft: props.periodo ? 0 : -2 }}>
                {<PeriodoComponent />}
              </Grid>
            </Grid>
          </ContainerAux>
        ) : (
          <ContainerAux>
            <PeriodoComponent />
          </ContainerAux>
        )}
      </HeaderContainer>
      {props?.subtitle_date ? (
        <SmallerTitle mb="8px">
          {props.obj_datas.data_fim && props.obj_datas.data_ini
            ? `${props.data_fim_apenas ? '' : `${props.obj_datas.data_ini} - `}${props.obj_datas.data_fim}`
            : ''}
        </SmallerTitle>
      ) : null}
    </>
  );
}
