import axios from 'axios';
import { signal } from './abortAPI';

const baseURL_prefix = `https://`;
const baseURL_default = `www`; // aponta para produção por padrão
const baseURL_suffix = `comdinheiro.com.br`;
const baseURL_path = `Clientes`;

const buildCompleteURL = (subdominio: string, dominio: string) => {
  return `${baseURL_prefix}${subdominio}.${dominio}/${baseURL_path}/`;
};

let baseDominio = window.localStorage.getItem('BASE_DOMINIO');
let baseSubdominio = window.localStorage.getItem('BASE_SUBDOMINIO');

let baseURL = buildCompleteURL(baseSubdominio ?? baseURL_default, baseDominio ?? baseURL_suffix);
export const getBaseURL = () => baseURL;

/**
 * Definir qual é o Subdominio das chamadas da API
 */
(window as any).setSubdominio = (newBaseSubdominio: string) => {
  localStorage.setItem('BASE_SUBDOMINIO', newBaseSubdominio);
  baseSubdominio = newBaseSubdominio;
  baseURL = buildCompleteURL(baseSubdominio ?? baseURL_default, baseDominio ?? baseURL_suffix);
};

/**
 * Definir qual é o Dominio das chamadas da API
 */
(window as any).setDominio = (type: 'nelogica' | 'comdinheiro') => {
  const dominio = type === 'nelogica' ? 'nelogica.net.br' : 'comdinheiro.com.br';
  localStorage.setItem('BASE_DOMINIO', dominio);
  baseDominio = dominio
  baseURL = buildCompleteURL(baseSubdominio ?? baseURL_default, baseDominio ?? baseURL_suffix);
};

/**
 * Remover a URL definida no localStorage e utilizar o valor default
 */
(window as any).resetBaseURL = () => {
  localStorage.removeItem('BASE_SUBDOMINIO');
  localStorage.removeItem('BASE_DOMINIO');
  baseDominio = baseURL_default
  baseSubdominio = baseURL_suffix
  baseURL = buildCompleteURL(baseURL_default, baseURL_suffix);
};

const api = (apiKey?: string | null, contentType?: string) => {
  const headers: { [key: string]: string } = {
    'Content-Type': contentType ?? 'application/json',
  };
  if (apiKey) headers.Authorization = `Bearer ${apiKey}`;

  const axiosVar = axios.create({
    method: 'post',
    baseURL: baseURL,
    headers,
    signal,
  });

  function isValidJson(response: any) {
    if (typeof response === 'object' && response !== null) {
      try {
        JSON.stringify(response);
        return true;
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  axiosVar.interceptors.request.use(
    function (config) {
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  axiosVar.interceptors.response.use(
    function (response) {
      if (!isValidJson(response.data)) {
        window.location.replace('/');
      }
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  return axiosVar;
};

export default api;
