import React, { useEffect } from 'react';

import { useAppSelector, useAppDispatch } from './reduxHooks';

import { fetchResumoCart, fetchResumoCartPerf } from './api/resumoCartAPI';
import { fetchPerfHist } from './api/perfHistAPI';
import { fetchPosCon } from './api/posConAPI';
import { fetchCartExp } from './api/cartExpAPI';
import { fetchFluxoAtivos } from './api/fluxoAtivosAPI';
import { fetchIFsCaixa } from './api/fluxoCaixaAPI';
import { fetchEstats } from './api/estatsAPI';
import { typeAbas } from './PreferenceStyleInterface/IPreferenceLiberacao';
import { ABAS_DEFAULT } from './PreferenceStyleInterface/IPreferenceLiberacao';

export function useFechAPIs(isLoaded: boolean, loading: boolean, dispatchPDF?: boolean, fluxoAtivos?: boolean) {
    const dispatch = useAppDispatch();
    const [
        loadedCarteiraList,
        preferenceCustomizacao,
        preferenceLiberacao,
    ] = useAppSelector((state) => [
        state.preference.loadedCarteiraList,
        state.preference.preferenceStyle.customizacao,
        state.preference.preferenceStyle.liberacao,
    ]);
    const abas = preferenceLiberacao?.abas ?? ABAS_DEFAULT;
    const mapeamentoFuncoes: Partial<Record<typeAbas, (param?: any) => any>> = {
        ResCart: () => fetchResumoCart(),
        PerfHist: () => fetchPerfHist(),
        PosCon: () => fetchPosCon(),
        CartExp: () => fetchCartExp(),
        FluxoAtivo: (date: string) => fetchFluxoAtivos(date),
        FluxoCaixa: () => fetchIFsCaixa(),
        EstatAvancada: () => fetchEstats(),
    }

    useEffect(() =>{
        if (loadedCarteiraList && !isLoaded && !loading && !dispatchPDF) {
            // 20/02/2025: NÃO FUNCIONA COM O PDF ATUAL, APENAS COM O PDF QUE ACEITA LAYOUTS DA ISSUE CMDDAPP-576
            // for (const page of abas) {
            //     if (page in mapeamentoFuncoes) {
            //         dispatch(mapeamentoFuncoes[page]?.());
            //     }
            // }
            // if (preferenceCustomizacao?.ResCart_percent) dispatch(fetchResumoCartPerf());
            for(const [page, fetchPage] of Object.entries(mapeamentoFuncoes)){
                // API de fluxo de ativos for�ada a ser mes_atual quando n�o for chamada da pr�pria p�gina
                if (fluxoAtivos || page !== 'FluxoAtivo') dispatch(fetchPage());
                else dispatch(fetchPage('mes_atual'));
            }
            if (preferenceCustomizacao?.ResCart_percent) dispatch(fetchResumoCartPerf());
        }
    }, [loadedCarteiraList, isLoaded])
}

export default useFechAPIs;
