import { Grid } from '@mui/material';
import React, { useEffect, useRef, useState, Suspense } from 'react';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import LoadingModal from '../LoadingModal';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from 'styled-components';
import './style.css';
import 'jspdf-autotable';
import 'svg2pdf.js'
import '../../../assets/fonts/OpenSans-Regular-normal'
import '../../../assets/fonts/OpenSans-Bold-bold'

import * as FuncoesPDF from './Biblioteca/FuncoesPDF'
import * as helperPDF from './Biblioteca/helperPDF'
import { docJSPDF } from './Components/docJSPDF';

import { ResCartPDF, IResCartData } from './Pages/ResCartPDF';
import { PerfHistPDF, IPerfHistData } from './Pages/PerfHistPDF';
import { PosConPDF, IPosConData } from './Pages/PosConPDF';
import { CartExpPDF, ICartExpData } from './Pages/CartExpPDF';
import { FluxoAtivosPDF, IFluxoAtivosData } from './Pages/FluxoAtivosPDF';
import { EstatAvancandaPDF, IEstatAvancadaData } from './Pages/EstatAvancadaPDF';
import { desenharCapaPDF } from './Pages/CapaPDF'
import { desenharDisclaimerPDF } from './Pages/DisclaimerPDF'
import { desenharFooterPDF } from './Pages/FooterPDF'
import { gerarGraficos } from './Biblioteca/GerarGraficos';
import GerarPaginas from './Biblioteca/GerarPaginas';
import { ABAS_DEFAULT, typeAbas } from '../../../services/PreferenceStyleInterface/IPreferenceLiberacao';
import { desenharContracapaPDF } from './Pages/ContracapaPDF';

export interface IListaGraficos{
    graficoClasse?: boolean;
    graficoCustodiante?: boolean;
    linhaRetorno?: boolean;
    areaPatrimonio?: boolean;
    barraFinanceiro?: boolean;
    pizzaCartExp?: boolean;
}

export interface IPageData {
    ResCart: IResCartData | null,
    PerfHist: IPerfHistData | null
    PosCon: IPosConData | null
    RetornoAtivo: IPosConData | null
    CartExp: ICartExpData | null
    FluxoAtivo: IFluxoAtivosData | null
    EstatAvancada: IEstatAvancadaData | null
}

const initialState: IPageData = {
    ResCart: null,
    PerfHist: null,
    PosCon: null,
    CartExp: null,
    RetornoAtivo: null,
    FluxoAtivo: null,
    EstatAvancada: null
};

export default function GerarPDFTodos2(props: any) {
    const theme = useTheme();
    const { state, search } = useLocation();
    const navigate = useNavigate();
    const [
        params,
        preferenceImagem,
        preferenceCustomizacao,
        preferenceImagemAPI,
        preferenceLiberacao,
        preferenceLayoutPDF,
        obj_datas,
        carteiraList,
        carteira,
    ] = useAppSelector((state) => [
        state.preference.params,
        state.preference.preferenceStyle.imagem,
        state.preference.preferenceStyle.customizacao,
        state.preference.preferenceStyle.imagemAPI,
        state.preference.preferenceStyle.liberacao,
        state.preference.preferenceStyle.layoutPDF,
        state.resumoCart.obj_datas,
        state.preference.carteiraList,
        state.preference.params.carteira,
    ]);

    // obtem unidade monetária
    const moneyPrefix = helperPDF.getMoneyPrefix(carteiraList, carteira);

    // indica se o PDF já foi gerado
    const [isPDFCreated, setIsPDFCreated] = useState<boolean>(false);

    // guarda os dados das tabelas e graficos de todas as paginas
    const [pageData, setPageData] = useState<IPageData>(initialState);
    // indica se todos os dados já carregaram
    const [isLoadedPageData, setIsLoadedPageData] = useState<boolean>(false);

    // referencia para o HTML dos gráficos
    const containerRefs = useRef<Record<string, HTMLDivElement | null>>({});
    // indica se a animação de todos os gráficos terminou
    const [isLoadedGraficos, setIsLoadedGraficos] = useState<boolean>(false);
    // guarda os compomentes dos gráficos
    const [graficos, setGraficos] = useState<any []>([]);
    // Objeto com cada gráfico e um bool para indicar se está carregado ou não
    const chartLoaded = useRef<IListaGraficos>({});

    const abas = preferenceLiberacao?.abas ?? ABAS_DEFAULT;

    useEffect(() => {
        let allDataLoaded = true;
        for (const page of abas) {    // verifica se todas as páginas já carregaram
            if (page in pageData && !pageData[page as keyof typeof pageData]) {
                allDataLoaded = false;
            }
        }
        if (allDataLoaded) {
            setIsLoadedPageData(true);
        }
    }, [pageData])

    /**
     * Quando todos os dados estiverem disponíveis, gera e carrega os gráficos
     */
    useEffect(() => {
        if(isLoadedPageData){
            const listaGraficos = chartLoaded.current = helperPDF.listarGraficos(pageData, abas, preferenceCustomizacao);
            setGraficos(gerarGraficos(pageData, theme, setIsLoadedGraficos, listaGraficos, chartLoaded, preferenceCustomizacao, moneyPrefix));
        }
    }, [isLoadedPageData])

    async function gerarPDF() {
        // datas formatadas
        const datasPDF = helperPDF.generateDatasPDF(obj_datas);

        // alias da carteira
        const alias_portfolio = carteiraList.filter((item) => item.nome_portfolio.toUpperCase() === params.carteira?.toUpperCase())[0].alias_nome_portfolio;

        // só mostra o disclaimer da carteiraList se possuir um PDF_disclaimer cadastrado na preference_whitelabel
        let disclaimer = preferenceCustomizacao?.PDF_disclaimer ?? null;
        if(disclaimer){
            const disclaimerCarteiraList = carteiraList.find((item) => item.nome_portfolio.toUpperCase() === carteira?.toUpperCase())?.disclaimer;
            // Se houver disclaimer na carteiraList, utiliza no lugar do disclaimer da preference_whitelabel
            if(disclaimerCarteiraList){
                disclaimer = disclaimerCarteiraList;
            }
        }

        // organização das páginas no PDF. Por padrão utiliza as abas do usuário. OBS: se o usuário não estiver liberado na Aba, não é possível inserir esta aba no PDF
        const layoutPaginasPDF = preferenceLayoutPDF?.layoutPaginas ?? abas;

        // criação do PDF
        const docObj = new docJSPDF(theme, preferenceCustomizacao?.PDF_footerTopo);
        docObj.useDefaultTitleStyles(); // utiliza os estilos pré-definidos para os textos

        // obtem os SVGs e HTMLs de cada página
        const resCartHTML = helperPDF.getHTMLElements(graficos, containerRefs, 'ResCart');
        const perfHistHTML = helperPDF.getHTMLElements(graficos, containerRefs, 'PerfHist');
        const cartExpHTML = helperPDF.getHTMLElements(graficos, containerRefs, 'CartExp');

        // desenha a capa do PDF
        if(preferenceImagemAPI?.CapaPDF){
            desenharCapaPDF(docObj.doc, theme, alias_portfolio ?? carteira, datasPDF.datasFim, preferenceCustomizacao, preferenceImagemAPI?.CapaPDF);
        }

        // desenha o disclaimer após a capa do PDF
        if (disclaimer && preferenceCustomizacao?.PDF_disclaimerPosicao === 'primeira') {
            desenharDisclaimerPDF(docObj.doc, theme, disclaimer, preferenceCustomizacao?.PDF_disclaimerSize ?? 11, false);
        }

        let perfHistPDF: PerfHistPDF | null = null;
        if(pageData.PerfHist && layoutPaginasPDF.includes('PerfHist')){
            perfHistPDF = new PerfHistPDF(docObj, pageData.PerfHist, perfHistHTML, preferenceLayoutPDF?.PerfHist);
        }

        const pageFunctions = {
            ResCart: async () => {
                if (pageData.ResCart) {
                    // passa a PerfHist para poder criar o gráfico de linha no ínicio do PDF
                    return await new ResCartPDF(docObj, pageData.ResCart, resCartHTML, preferenceCustomizacao, preferenceLayoutPDF?.ResCart, perfHistPDF, moneyPrefix).createPage();
                }
            },
            PerfHist: async () => {
                if (perfHistPDF) {
                    return await perfHistPDF.createPage();
                }
            },
            PosCon: async () => {
                if (pageData.PosCon) {
                    return new PosConPDF(docObj, pageData.PosCon, 'PosCon', 'Posição Consolidada', preferenceLayoutPDF?.PosCon ?? ['PosCon'], preferenceCustomizacao).createPage();
                }
            },
            RetornoAtivo: async () => {
                if (pageData.RetornoAtivo) {
                    return new PosConPDF(docObj, pageData.RetornoAtivo, 'RetornoAtivo', 'Retorno por Ativo', preferenceLayoutPDF?.RetornoAtivo ?? ['RetornoAtivo'], preferenceCustomizacao).createPage();
                }
            },
            CartExp: async () => {
                if (pageData.CartExp && pageData.CartExp.aviso_carteira_explodida?.houve_explosao) {    // só adiciona no PDF se houve explosão
                    return await new CartExpPDF(docObj, pageData.CartExp, cartExpHTML, preferenceLayoutPDF?.CartExp).createPage();
                }
            },
            FluxoAtivo: async () => {
                if (pageData.FluxoAtivo) {
                    return new FluxoAtivosPDF(docObj, pageData.FluxoAtivo, preferenceLayoutPDF?.FluxoAtivo).createPage();
                }
            },
            EstatAvancada: async () => {
                if (pageData.EstatAvancada) {
                    return new EstatAvancandaPDF(docObj, pageData.EstatAvancada, preferenceLayoutPDF?.EstatAvancada).createPage();
                }
            },
        }

        // Gera os componentes das páginas
        const componentsPDF: any[] = [];
        for(const pagina of layoutPaginasPDF){
            if(pageData[pagina as keyof typeof pageData]){
                const pdfPage = await pageFunctions[pagina as keyof typeof pageFunctions]();
                if (pdfPage) {
                    componentsPDF.push(pdfPage);
                }
            }
        }

        await FuncoesPDF.desenhaPDF(docObj, componentsPDF);

        // desenha o disclaimer na última página
        if (disclaimer && preferenceCustomizacao?.PDF_disclaimerPosicao === 'ultima') {
            desenharDisclaimerPDF(docObj.doc, theme, disclaimer, preferenceCustomizacao?.PDF_disclaimerSize ?? 11, true);
        }

        // desenha o footer para todas as páginas (menos contracapa)
        if(preferenceImagemAPI?.LogoPDF){
            desenharFooterPDF(docObj.doc, theme, alias_portfolio ?? carteira, datasPDF, preferenceCustomizacao, preferenceImagemAPI?.LogoPDF, preferenceImagem);
        }

        // desenha a imagem da contracapa
        if(preferenceImagemAPI?.ContracapaPDF){
            desenharContracapaPDF(docObj.doc, preferenceImagemAPI?.ContracapaPDF);
        }

        if (obj_datas.data_ini && obj_datas.data_fim) {
            const nome_arquivo = `Extrato_${carteira + datasPDF.data_iniPDF + datasPDF.data_fimPDF + datasPDF.dataElaboracao}.pdf`;
            const prefix = props.isViaURL && (window as any).env.REACT_APP_PDFNAME ? `${(window as any).env.REACT_APP_PDFNAME}.` : '';  // adicionar prefixo com o nome do cliente(Utilizado no agendamento para evitar nomes duplicados)
            docObj.doc.save(prefix + nome_arquivo);
        } else if (datasPDF.dataElaboracao) {
            docObj.doc.save('Extrato' + datasPDF.dataElaboracao + '.pdf');
        } else {
            docObj.doc.save('Extrato.pdf');
        }
        setIsPDFCreated(true);
        const url = `${state}${carteira !== params.carteira ? '' : search}`;
        if (state && url) {
            navigate(url);
        }
        return;
    }

    useEffect(() => {
        if (isLoadedGraficos && isLoadedPageData) {
            gerarPDF();
        }
    }, [isLoadedGraficos, isLoadedPageData])

    return <>
        {isPDFCreated ? null : <LoadingModal loading={!isPDFCreated} showMinimizar={false}/>}
        {!abas ? null : <GerarPaginas setPageData={setPageData} abas={abas} moneyPrefix={moneyPrefix}/>}
        <div style={{visibility: 'hidden'}}>
            {graficos.length > 0 ? graficos.map((graph, index) => (
                <Grid key={graph.name} ref={(el) => (containerRefs.current[graph.name] = el)} style={graph.styleContainer}>
                    {graph.component}
                </Grid>
            )): null}
        </div>
    </>
}
