import { ITableColumns } from '../../../../components/Table/TableHeader';
import { docJSPDF } from '../Components/docJSPDF';
import { GraficoLinhaAreaPDF } from '../Components/Graficos/GraficoLinhaAreaPDF';
import { GraficoBarraPDF } from '../Components/Graficos/GraficoBarraPDF';
import { layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';

import * as funcoesPDF from '../Biblioteca/FuncoesPDF'
import * as helperPDF from '../Biblioteca/helperPDF'
import { ComponentPDF } from '../Components/ComponentPDF';
import { PagePDF } from './PagePDF';

export interface IPerfHistData {
    rowsRetornos: any,
    columnsRetornos: ITableColumns,
    rowsMovim: any,
    columnsMovim: ITableColumns,
    data: any,
    dataEvol: any,
    assetsApi: string [] | null,
    barChartRend12Data: any,
    moneyPrefix: string | null,
}

const DEFAULT_LAYOUT = [
    'RetornosNominais',
    'AplicacaoResgate',
    ['linhaRetorno', 'areaPatrimonio'],
    'barraFinanceiro'
];

export class PerfHistPDF extends PagePDF {
    htmlElements: any;

    constructor(docObj: docJSPDF, data: IPerfHistData, htmlElements: any, layout?: layoutType) {
        super(docObj, 'PerfHist', data, layout ?? DEFAULT_LAYOUT);

        this.htmlElements = htmlElements;
    }

    /**
     * Gera um array com todos os componentes da página, utilizando o layout
     *
     * @returns Promise<ComponentPDF[]> Array com todos os componentes da página
     */
    async createPage(): Promise<ComponentPDF[]> {
        const components: ComponentPDF[] = [];
        const tituloComEstilo = this.docObj.criarTituloPagina('Performance Histórica');
        components.push(funcoesPDF.gerarTexto(tituloComEstilo, this.pageName, {level: 0}));
        for(const config of this.layout){
            let component = null;
            switch (config.id) {
                case 'RetornosNominais': {
                    // remove a primeira coluna(box color lateral com o ano)
                    const body = helperPDF.gerarBodyComCor(this.data.rowsRetornos, this.data.columnsRetornos.slice(1), this.docObj.theme, 10, this.data.columnsRetornos[0].rowSpan, 'ano', this.data.columnsRetornos[0].bgColor);
                    component = (this.createConfigTable(config, null, this.data.columnsRetornos, 'Retornos Nominais da Carteira x Benchmarks', 1 ,body, 'center'));
                    break;
                }
                case 'AplicacaoResgate': component = this.createConfigTable(config, this.data.rowsMovim, this.data.columnsMovim, 'Aplicações e Resgates'); break;
                case 'linhaRetorno':  component = await this.getComponentGraficoLinhaRetorno(config); break;
                case 'areaPatrimonio': {
                    const areaPatrimonio = new GraficoLinhaAreaPDF(this.pageName, config, this.htmlElements['areaPatrimonio'], 'Evolução do Patrimônio', ['Carteira']);
                    component = await areaPatrimonio.criarGrafico(this.docObj);
                    break;
                }
                case 'barraFinanceiro': {
                    const barraFinanceiro = new GraficoBarraPDF(this.pageName, config, this.htmlElements['barraFinanceiro'], 'Ganho Financeiro (últimos 12m)', null, 15);
                    component = await barraFinanceiro.criarGrafico(this.docObj);
                    break;
                }
            }
            if(component){
                components.push(component);
            }
        }
        if(components.length === 1) return [];   // só possui o título, então não deve incluir
        return components;
    }

    /**
     * Gera o componente para o Grafico de linha 'Retorno Acumulado'
     * @param config Configurações do componente
     * @param sizeRow (Default 4) Número de elementos por linha na legenda
     * @param width (Opcional) Tamanho do SVG
     * @param height (Opcional) Altura do SVG
     * @param alignLegenda (Opcional, default 'center') Alinhamento da legenda
     * @param fontSize (Opcional) Tamanho da fonte na legenda
     * @returns Promise<ComponentPDF|null> Componentes do gráfico de linha
     */
    async getComponentGraficoLinhaRetorno(config: any, sizeRow = 4, width?: number, height?: number, alignLegenda?: string, fontSize?: number ): Promise<ComponentPDF|null>{
        // substitui ativo com o nome Carteira_retorno
        const data = this.data.assetsApi.map((item: any) => item === 'Carteira_retorno' ? 'Carteira' : item);
        const areaPatrimonio = new GraficoLinhaAreaPDF(this.pageName, config, this.htmlElements['linhaRetorno'], 'Retorno Acumulado', data, sizeRow, width, height);
        return await areaPatrimonio.criarGrafico(this.docObj, fontSize, alignLegenda);
    }

    /**
     * Cria os atributos da tabela para o jsPDF
     * @param config Configurações do componente
     * @param row Linha
     * @param col Coluna
     * @param title Titulo
     * @param columnStyleIndex (Default 0) Index do estilo aplicado no campo 'columnStyles'
     * @param body (Opcional) Permite passar o corpo da tabela formatadao direto
     * @returns ComponentPDF Componente da tabela para o PDF
     */
    private createConfigTable(config: any, row: any, col: any, title: string, columnStyleIndex = 0, body?: any ,headHalign = 'right') {
        const titleConfig = this.docObj.criarTituloTabela(title);
        const tableOptions = {
            headStyles: { halign: headHalign, textColor: this.docObj.theme.titleFontColor },
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            columnStyles: { [columnStyleIndex]: { halign: 'left' } },
            styles: {
                halign: 'right',
                minCellHeight: 8,
            },
            body: body || funcoesPDF.createTableBody(row, col),
            head: funcoesPDF.createTableHead(col),
        }
        return funcoesPDF.gerarTabela(config, this.pageName, tableOptions, titleConfig);
    }
}
