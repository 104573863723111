import React, { useCallback, useEffect, useState } from 'react';
import { Line } from '../../../components/Line';
import Header from '../../../components/Header';
import { useAppDispatch, useAppSelector } from '../../../services/reduxHooks';
import { sendAnalyticsData } from '../../../services/api/preferenceAPI';
import { Grid } from '@mui/material';
import EnhancedTable from '../../../components/Table';
import { AlignTypes, ITableColumns } from '../../../components/Table/TableHeader';
import { FormatTypes, helperFormat } from '../../../services/helper';
import { Subtitulo } from '../../../components/Titulo';
import LoadingModal from '../LoadingModal';
import FixedLoadingButton from '../../../components/FixedLoadingButton';
import Dropdown from '../../../components/Dropdown';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import useFetchAPIs from '../../../services/useFetchAPIs';

interface Props {
  ignoraMesAtual?: boolean | null,
  dispatchPDF?: boolean,
  sendData?:any
}

export default function FluxoAtivos({ ignoraMesAtual, dispatchPDF, sendData }: Props) {
  const dispatch = useAppDispatch();
  const [obj_datas, fluxo_de_ativos, isLoaded, loading, loadedCarteiraList, isLoadingMinimized, params, preferenceCustomizacao,] = useAppSelector((state) => [
    state.fluxoAtivos.obj_datas,
    state.fluxoAtivos.fluxo_de_ativos,
    state.fluxoAtivos.isLoaded,
    state.fluxoAtivos.loading,
    state.preference.loadedCarteiraList,
    state.loadMinimized.isLoadingMinimized,
    state.preference.params,
    state.preference.preferenceStyle.customizacao,
  ]);

  useEffect(() => {
    if (!dispatchPDF) document.title = `${(window as any).env.REACT_APP_TABNAME} - Fluxo de Ativos`;
  }, []);

  useEffect(() => {
    if (isLoaded && !dispatchPDF) dispatch(sendAnalyticsData(location.href));
  }, [params, isLoaded]);

  useFetchAPIs(isLoaded, loading, dispatchPDF, true);

  const [tiposAtv, setTiposAtv] = useState<(string | number)[]>([])
  const [tiposOps, setTiposOps] = useState<(string | number)[]>([])
  const [mudouFiltro, setMudouFiltro] = useState<boolean>(false)
  const [selectedTypes, setSelectedTypes] = useState<(string | number)[]>([])

  useEffect(() => {
    const atualizaAtv = isLoaded && fluxo_de_ativos ? Array.from(new Set(fluxo_de_ativos.slice(1).map(row => row[1]))) : []
    setTiposAtv(atualizaAtv)
    const atualizaOps = isLoaded && fluxo_de_ativos ? Array.from(new Set(fluxo_de_ativos.slice(1).map(row => row[3]))) : []
    setTiposOps(atualizaOps)
    setMudouFiltro(false)
  }, [fluxo_de_ativos, isLoaded])

  useEffect(() => {
    setSelectedTypes([...tiposAtv, ...tiposOps])
  }, [tiposAtv, tiposOps])

  let dadosFluxoAtivos = isLoaded && fluxo_de_ativos ? fluxo_de_ativos.slice(1) : null;

  if(!dispatchPDF){
    dadosFluxoAtivos = isLoaded && fluxo_de_ativos ?
      fluxo_de_ativos.slice(1)
      .filter(row => selectedTypes.includes(row[1]))
      .filter(row => selectedTypes.includes(row[3]))
    : null;
  }

  const rowsFluxoAtivos =
    isLoaded && dadosFluxoAtivos
      ? dadosFluxoAtivos
        .map((row) => {
          let i = 0;
          const dataAux = row[i++] as string;
          return {
            data: dataAux.split('-').reverse().join('/'),
            tipo: row[i++],
            desc: row[i++],
            op: row[i++],
            quant: helperFormat(row[i++], FormatTypes.truncate),
            valBruto: helperFormat(row[i++], FormatTypes.decimals, 2),
            valLiquido: helperFormat(row[i++], FormatTypes.decimals, 2),
          };
        })
      : null;

  const columnsFluxoAtivos: ITableColumns[] = [
    {
      id: 'data',
      label: 'Data',
      freeze: true,
      align: AlignTypes.left,
    },
    {
      id: 'tipo',
      label: 'Tipo',
      align: AlignTypes.left,
    },
    {
      id: 'desc',
      label: 'Descrição',
      align: AlignTypes.left,
    },
    {
      id: 'op',
      label: 'Operação',
      align: AlignTypes.left,
    },
    {
      id: 'quant',
      label: 'Quantidade',
      align: AlignTypes.right,
    },
    {
      id: 'valBruto',
      label: 'Valor Bruto',
      align: AlignTypes.right,
    },
    {
      id: 'valLiquido',
      label: 'Valor Líquido',
      align: AlignTypes.right,
    },
  ];


  const onSelect = (item: (string | number)[]) => {
    setSelectedTypes(item)
    setMudouFiltro(true)
  }
  const optionNameLabel = (item: string | number) => {
    return String(item);
  }

  const DropdownIcon = (props: any) => <FontAwesomeIcon
    icon={faFilter as IconProp}
    style={{
      color: props.color,
      cursor: props.cursor,
    }}
    fontSize={'0.8rem'}
  />

  const cachedFiltro = useCallback(() => (<Dropdown
    title="Filtros"
    selected={selectedTypes}
    options={[...tiposAtv, ...tiposOps]}
    onSelect={onSelect}
    optionNameLabel={optionNameLabel}
    secondColumnSeparator={tiposAtv.length}
    column1Title="Tipo Ativo"
    column2Title="Tipo Operação"
    botoesMarcarDesmarcar
    IconComponent={DropdownIcon}
  />), [tiposAtv, tiposOps, selectedTypes]);

  useEffect(() => {
    if (dispatchPDF) {
      const data = {
        rowsFluxoAtivos,
        columnsFluxoAtivos,
      }
      sendData('FluxoAtivo', data);
    }
  }, [])
  if (dispatchPDF) {
    return <></>;
  }

  return (
    <>
      <Grid>
        <Header
          title="Fluxo de Ativos"
          periodo
          obj_datas={obj_datas}
          subtitle_date
          containerAux
          filtroTipo={cachedFiltro}
        />
        <Line />
      </Grid>
      <Grid container spacing={3}>
        {isLoaded ? (
          <Grid item xs={12}>
            <Subtitulo mb="8px">Movimentações</Subtitulo>
            {isLoaded && fluxo_de_ativos && fluxo_de_ativos.length > 1 ? (
              rowsFluxoAtivos && rowsFluxoAtivos.length == 0 && mudouFiltro ? (
                <span>Nenhuma movimentação encontrada para os filtros selecionados</span>
              ) : (<EnhancedTable
                rows={rowsFluxoAtivos}
                columns={columnsFluxoAtivos}
                height={500}
                noScroll={true}
                lineBreak={true}
                headerFixed={true}
                isFluxo={true}
              />)
            ) : (
              <span>Nenhuma movimentação encontrada neste período</span>
            )}
          </Grid>
        ) : null}
      </Grid>
      <LoadingModal loading={((!loadedCarteiraList || !isLoaded) && !isLoadingMinimized) ?? false} />
      <FixedLoadingButton loading={(!loadedCarteiraList || !isLoaded) ?? false} isLoadingMinimized={isLoadingMinimized} />
    </>
  );
}
