import { docJSPDF } from '../Components/docJSPDF';
import { ITableColumns } from '../../../../components/Table/TableHeader';
import { layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';
import { IPreferenceCustomizacao } from '../../../../services/PreferenceStyleInterface/IPreferenceCustomizacao';

import * as funcoesPDF from '../Biblioteca/FuncoesPDF'
import * as helperPDF from '../Biblioteca/helperPDF'
import { ComponentPDF } from '../Components/ComponentPDF';
import { PagePDF } from './PagePDF';

export interface IPosConData {
    rowsDados: any,
    columnsDados: ITableColumns
}

export class PosConPDF extends PagePDF {
    title: string;  // título da página
    preferenceCustomizacao: IPreferenceCustomizacao | null;

    constructor(docObj: docJSPDF, data: IPosConData, pageName: string, title: string, layout: layoutType, preferenceCustomizacao: IPreferenceCustomizacao | null) {
        super(docObj, pageName, data, layout);
        this.title = title;
        this.preferenceCustomizacao = preferenceCustomizacao;
    }

    /**
     * Gera um array com todos os componentes da página, utilizando o layout
     *
     * @returns ComponentPDF[] Array com todos os componentes da página
     */
    createPage(): ComponentPDF[] {
        const components: ComponentPDF[] = [];
        const tituloComEstilo = this.docObj.criarTituloPagina(this.title, { marginBottom: 1 });
        components.push(funcoesPDF.gerarTexto(tituloComEstilo, this.pageName, {level: 0}));

        for (const config of this.layout) {
            let table = null;
            switch (config.id) {
                case 'PosCon':
                case 'RetornoAtivo': {
                    const generateStyle = (cell: any) => {
                      if (cell.bgColor) {
                          const styles = {
                              textColor: cell.color,
                              fillColor: cell.bgColor,
                              bold: cell.bold
                          }
                          return styles;
                      } else {
                          return {};
                      }
                  }
                  if(this.preferenceCustomizacao?.PDF_colorLegendPosCon) {
                    const body = helperPDF.gerarBodyComCor(this.data.rowsDados, this.data.columnsDados, this.docObj.theme, 2, 1, undefined, undefined, generateStyle);
                    table = this.createConfigTable(config, this.data.rowsDados, ['', ...this.data.columnsDados], generateStyle, body);
                  } else {
                    table = this.createConfigTable(config, this.data.rowsDados, this.data.columnsDados, generateStyle);
                  }
                  break;
                }
            }
            if (table) {
                components.push(table);
            }
        }

        if (components.length === 1) return [];   // só possui o título, então não deve incluir
        return components;
    }

    /**
     * Cria os atributos da tabela para o jsPDF. Gera a tabela com cores
     * @param config Configurações do componente
     * @param row Linha
     * @param col Coluna
     * @returns ComponentPDF Componente da tabela para o PDF
     */
    private createConfigTable(config: any, row: any, col: any, generateStyle?: (cell: any) => any, body?: any): ComponentPDF | null {
        const tableOptions = {
            headStyles: { halign: 'center', textColor: this.docObj.theme.titleFontColor },
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            columnStyles: { 0: { halign: 'left' } },
            styles: {
                halign: 'right',
                minCellHeight: 4,
            },
            body: body || funcoesPDF.createTableBody(row, col, null, generateStyle),
            head: funcoesPDF.createTableHead(col),
        }
        return funcoesPDF.gerarTabela(config, this.pageName, tableOptions);
    }

}