import '../style.css';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import autoTable, { CellInput, RowInput } from 'jspdf-autotable';
import '../../../../assets/fonts/OpenSans-Regular-normal'
import '../../../../assets/fonts/OpenSans-Bold-bold'
import { DefaultTheme } from 'styled-components';
import { IPreferenceCustomizacao } from '../../../../services/PreferenceStyleInterface/IPreferenceCustomizacao';
import { IPreferenceImagem } from '../../../../services/PreferenceStyleInterface/IPreferenceImagem';

/**
 * Desenha footer do PDF
 * @param doc PDF
 * @param theme Tema da aplicação
 * @param nome_portfolio Nome do portfólio
 * @param datasPDF Objeto com as datas formatadas
 * @param preferenceCustomizacao Opções de customização
 * @param CapaPDF Imagem da capa do PDF na base64
 */
export function desenharFooterPDF(doc: jsPDF, theme: DefaultTheme, nome_portfolio: string, datasPDF: any, preferenceCustomizacao: IPreferenceCustomizacao | null, logoPDF: string, preferenceImagem: IPreferenceImagem | null) {
    let relatDet = 'Carteira: ' + nome_portfolio;
    const totalPages = doc.getNumberOfPages();
    const posicaoRodape = (relatDet.length - 10) / 2;
    doc.setTextColor(theme.titleFontColor);

    if (preferenceCustomizacao?.PDF_footerTopo) {  // Adiciona o footer no topo da página
        /////////////////////////////////////////////////////////////////////////////////////////////////////FOOTER TOPO
        for (let i = 2; i <= totalPages; i++) {
            if (i == totalPages) {
                relatDet = 'Powered by Comdinheiro | ' + relatDet
            }
            doc.setPage(i);
            doc.addImage(logoPDF, 'JPEG',
                preferenceImagem?.LogoPDFPosX ?? 6,
                preferenceImagem?.LogoPDFPosY ?? 2,
                preferenceImagem?.LogoPDFWidth ?? 30,
                preferenceImagem?.LogoPDFHeight ?? 7
            );
            doc.setFontSize(7);
            doc.text('pg ' + (i - 1) + '/' + (totalPages - 1), 194, 292);
            doc.setFontSize(6);
            doc.text(
                '___________________________________________________________________________________________________________________________________________________________________________________',
                0,
                11,
            );
            autoTable(doc, {
                margin: { left: 80 },
                tableWidth: 130,
                startY: 0,
                bodyStyles: { valign: 'middle', halign: 'right', lineColor: '#FFFFFF' },
                theme: 'plain',
                styles: { fontSize: 6, textColor: theme.titleFontColor, minCellHeight: 0.1 },
                head: [],
                body: [[relatDet]],
            });
            autoTable(doc, {
                margin: { left: 80 },
                tableWidth: 130,
                startY: 3,
                bodyStyles: { valign: 'middle', halign: 'right', lineColor: '#FFFFFF' },
                theme: 'plain',
                styles: { fontSize: 6, textColor: theme.titleFontColor, minCellHeight: 0.1 },
                head: [],
                body: [['Data de elaboração: ' + datasPDF.dataElaboracaoRodape]],
            });
            autoTable(doc, {
                margin: { left: 80 },
                tableWidth: 130,
                startY: 6,
                bodyStyles: { valign: 'middle', halign: 'right', lineColor: '#FFFFFF' },
                theme: 'plain',
                styles: { fontSize: 6, textColor: theme.titleFontColor, minCellHeight: 0.1 },
                head: [],
                body: [[
                    'Período de análise: de ' +
                    datasPDF.data_analise.ini +
                    ' a ' +
                    datasPDF.data_analise.fim
                ]],
            });
        }
    } else {
        /////////////////////////////////////////////////////////////////////////////////////////////////////FOOTER DEFAULT
        for (let i = 2; i <= totalPages; i++) {
            doc.setPage(i);
            doc.addImage(logoPDF, 'JPEG',
                preferenceImagem?.LogoPDFPosX ?? 6,
                preferenceImagem?.LogoPDFPosY ?? 287,
                preferenceImagem?.LogoPDFWidth ?? 30,
                preferenceImagem?.LogoPDFHeight ?? 7
            );
            doc.setFontSize(7);
            doc.text('pg ' + (i - 1) + '/' + (totalPages - 1), 194, 292);
            doc.setFontSize(6);
            doc.text('___________________________________________________________________________________________________________________________________________________________________________________', 0, 285);
            doc.text(relatDet, 87 - posicaoRodape, 290);
            doc.text('Data de elaboração: ' + datasPDF.dataElaboracaoRodape, 113 + posicaoRodape, 290);
            doc.text(
                'Período de análise: de ' +
                datasPDF.data_analise.ini +
                ' a ' +
                datasPDF.data_analise.fim,
                113 + posicaoRodape,
                293,
            );
        }
        doc.text('Powered by Comdinheiro', 87 - posicaoRodape, 293);
    }
}