import {FormatTypes} from '../../../../../services/helper';
import { docJSPDF } from '../docJSPDF';
import { ComponentPDF } from '../ComponentPDF';
import { GraficoPDF } from './GraficoPDF';

import * as funcoesPDF from '../../Biblioteca/FuncoesPDF'
import * as helperPDF from '../../Biblioteca/helperPDF'

export class GraficoPizzaPDF extends GraficoPDF {
    dataTypeCustodiante:FormatTypes;
    moneyPrefix?:string;
    alignTitle?: string;

    constructor(pageName: string, config: any, svg: any, title?: string, legendData?: any, addAlphaIndex = false, dataTypeCustodiante = FormatTypes.percentage, moneyPrefix?: string, alignTitle = 'center') {
        super(pageName, config, svg, title, legendData);
        if (addAlphaIndex) {
            this.legendData = helperPDF.addAlphaIndex(this.legendData);
        }
        this.dataTypeCustodiante = dataTypeCustodiante;
        this.moneyPrefix = moneyPrefix;
        this.alignTitle = alignTitle;
    }

    /**
     * Gera o componente do gráfico de pizza
     * @param docObj PDF
     * @returns Promise<ComponentPDF | null> Componente do gráfico de pizza
     */
    async criarGrafico(docObj: docJSPDF): Promise<ComponentPDF | null> {
        const styles = {
            name: { halign: 'left', fontSize: 5, minCellHeight: 0.1, valign: 'middle' },
            value: { halign: 'right', fontSize: 5, minCellHeight: 0.1, valign: 'middle' }
        }
        const body =  helperPDF.formatLegendData(this.legendData, docObj.theme.chartColors, styles, 2, [['name', FormatTypes.text], ['value', this.dataTypeCustodiante]], this.moneyPrefix);
        const legenda = this.createOptionsTableLegend(body);
        return await this.configurarGrafico(docObj, 60, 60, legenda, this.alignTitle);
    }

    /**
     * Cria os atributos da legenda para o jsPDF
     * @param body Corpo
     * @returns Configurações da legenda
     */
    private createOptionsTableLegend(body: any) {
        const tableOptions = {
            body,
            head: [],
            tableWidth: 70, // valor default
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            theme: 'plain',
            columnStyles: { 1: { halign: 'left' } },
            rowPageBreak: 'avoid',
            styles: {
                ...funcoesPDF.GLOBAL_STYLES,
                minCellHeight: 0.1,
            },
        }
        return tableOptions;
    }
}