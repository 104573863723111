import 'jspdf-autotable';
import 'svg2pdf.js'
import '../../../../../assets/fonts/OpenSans-Regular-normal'
import '../../../../../assets/fonts/OpenSans-Bold-bold'
import { docJSPDF } from '../docJSPDF';
import { ComponentPDF } from '../ComponentPDF';
import { GraficoPDF } from './GraficoPDF';

/**
 * Gera um componente para o PDF do gráfico de Barra
 */
export class GraficoBarraPDF extends GraficoPDF {
    private marginBottom: number;
    constructor(pageName: string, config: any, svg: any, title?: string, legendData?: any, marginBottom = 0) {
        super(pageName, config, svg, title, legendData);
        this.marginBottom = marginBottom;
    }

    /**
     * Gera o componente do gráfico de Barra
     * @param docObj PDF
     * @returns Promise<ComponentPDF | null> Componente do gráfico de barra
     */
    async criarGrafico(docObj: docJSPDF, isHorizontal?: boolean): Promise<ComponentPDF | null> {
        if (isHorizontal) {
            return await this.configurarGrafico(docObj, 260, 65, null, 'center', 'center',this.marginBottom);
        }
        return await this.configurarGrafico(docObj, 200, 50, null, 'left', 'center',this.marginBottom);
    }

}
