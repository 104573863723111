import { docJSPDF } from '../docJSPDF';
import { ComponentPDF } from '../ComponentPDF';
import * as funcoesPDF from '../../Biblioteca/FuncoesPDF'

/**
 * Modelo para gerar os componentes dos graficos para o jsPDF
 * Permiter que o grafico tenha titulo e legenda
 */
export abstract class GraficoPDF {
    pageName: string;
    config: any;
    svg: any;
    title: string;
    legendData: any;

    constructor(pageName: string, config: any, svg: any, title?: string, legendData?: any) {
        this.pageName = pageName;
        this.config = config;
        this.svg = svg;
        this.title = title || '';
        this.legendData = legendData || null;
    }

    /**
     *
     * @param docObj PDF
     * @param width Largura do SVG
     * @param height Altura do SVG
     * @param legenda (Opcional) Tabela que representa a legenda
     * @param alignTitle (Default center) Alinhamento do titulo do grafico
     * @param alignLegend (Default center) Alinhamento da legenda do grafico
     * @param marginBottom (Default 0) Acrescenta uma distância na altura do Grafico
     * @returns
     */
    async configurarGrafico(docObj: docJSPDF, width: number, height: number, legenda?: any, alignTitle = 'center', alignLegend = 'center', marginBottom = 0): Promise<ComponentPDF | null> {
        const graficoClasseSVG: funcoesPDF.ISVG = {
            html: this.svg,
            width,
            height,
            marginBottom,
        }
        const titulo = this.title !== '' ? docObj.criarTituloGrafico(this.title) : null;
        const component = await funcoesPDF.gerarGrafico(this.config, this.pageName, graficoClasseSVG, titulo, legenda, alignTitle, alignLegend);
        return component;
    }

    abstract criarGrafico(docObj: docJSPDF): Promise<ComponentPDF | null>;
}
