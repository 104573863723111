import { ITableColumns } from '../../../../components/Table/TableHeader';
import { docJSPDF } from '../Components/docJSPDF';
import { ComponentPDF } from '../Components/ComponentPDF';
import * as funcoesPDF from '../Biblioteca/FuncoesPDF'
import { PagePDF } from './PagePDF';
import { layoutType } from '../../../../services/PreferenceStyleInterface/IPreferenceLayoutPDF';

export interface IFluxoAtivosData {
    rowsFluxoAtivos: any,
    columnsFluxoAtivos: ITableColumns,
}

const DEFAULT_LAYOUT = ['FluxoAtivos'];

export class FluxoAtivosPDF extends PagePDF {
    constructor(docObj: docJSPDF, data: IFluxoAtivosData, layout?: layoutType) {
        super(docObj, 'FluxoAtivos', data, layout ?? DEFAULT_LAYOUT);
    }

    /**
     * Gera um array com todos os componentes da página, utilizando o layout
     *
     * @returns ComponentPDF[] Array com todos os componentes da página
     */
    createPage(): ComponentPDF[] {
        const components: ComponentPDF[] = [];
        const tituloComEstilo = this.docObj.criarTituloPagina('Fluxo de Ativos');
        components.push(funcoesPDF.gerarTexto(tituloComEstilo, this.pageName, {level: 0}));
        for (const config of this.layout) {
            let table = null;
            switch (config.id) {
                case 'FluxoAtivos': table = this.createConfigTable(config, this.data.rowsFluxoAtivos, this.data.columnsFluxoAtivos, 'Movimentações'); break;
            }
            if (table) {
                components.push(table);
            }
        }
        if (components.length === 1) return [];   // só possui o título, então não deve incluir
        return components;
    }

    /**
     * Cria os atributos da tabela para o jsPDF
     * @param config Configurações do componente
     * @param row Linha
     * @param col Coluna
     * @param title Titulo
     * @returns ComponentPDF Componente da tabela para o PDF
     */
    private createConfigTable(config: any, row: any, col: any, title: string): ComponentPDF | null {
        const titleConfig = this.docObj.criarTituloTabela(title);
        const tableOptions = {
            headStyles: { halign: 'right', textColor: this.docObj.theme.titleFontColor },
            bodyStyles: { fillColor: '#FFFFFF', valign: 'middle' },
            columnStyles: Object.fromEntries(    // as quarto primeiras colunas da tabela são alinhadas para a esquerda. O restante é alinhado para a direita
                [0, 1, 2, 3].map((col) => [col, { halign: 'left' }])
            ),
            styles: {
                halign: 'right',
                minCellHeight: 7,
            },
            body: funcoesPDF.createTableBody(row, col),
            head: funcoesPDF.createTableHead(col),
        }
        return funcoesPDF.gerarTabela(config, this.pageName, tableOptions, titleConfig);
    }
}
