import '../style.css';
import jsPDF from 'jspdf';
import '../../../../assets/fonts/OpenSans-Regular-normal'
import '../../../../assets/fonts/OpenSans-Bold-bold'
import { IPreferenceCustomizacao } from '../../../../services/PreferenceStyleInterface/IPreferenceCustomizacao';
import { DefaultTheme } from 'styled-components';

/**
 * Gerar o nome completo do mês em UpperCase em português
 * @param mes Mês(1 - 12)
 * @returns Nome completo do mês em UpperCase
 */
function getNomeMes(mes: string) {
    const data = new Date(2025, Number(mes) - 1);
    return data.toLocaleString('pt-BR', { month: 'long' }).toUpperCase();
}

/**
 * Desenha a capa do PDF e o texto da data
 * @param doc PDF
 * @param theme Tema da aplicação
 * @param nome_portfolio Nome do portfólio
 * @param datasFim Array com [dia, mes, ano]
 * @param preferenceCustomizacao Opções de customização
 * @param CapaPDF Imagem da capa do PDF na base64
 */
export function desenharCapaPDF(doc: jsPDF, theme: DefaultTheme, nome_portfolio: string, datasFim: string [], preferenceCustomizacao: IPreferenceCustomizacao | null, CapaPDF: string) {
    doc.addImage(CapaPDF, 'JPEG', 0, 0, 210, 300);
    doc.setTextColor(preferenceCustomizacao?.PDF_dataTextColor ?? theme.fontColorMenu);
    doc.setFontSize(preferenceCustomizacao?.PDF_dataCapaSize ?? 16);
    if (preferenceCustomizacao?.PDF_dataCapa ?? true) {
        const dataSeparador = preferenceCustomizacao?.PDF_dataSeparador ?? '|';
        let textoDataCapa = getNomeMes(datasFim[1]) + ' ' + dataSeparador + ' ' + datasFim[2];
        if (preferenceCustomizacao?.PDF_nomeCarteira) { // adiciona nome da carteira ao lado da data na capa do PDF
            textoDataCapa += ' - ' + (nome_portfolio);
        }
        doc.text(textoDataCapa, preferenceCustomizacao?.PDF_dataCapaPosX ?? 67, preferenceCustomizacao?.PDF_dataCapaPosY ?? 230, { maxWidth: 100, align: preferenceCustomizacao?.PDF_dataCapaCentralizado ? 'center' : 'left' });
    }
    doc.addPage();
}